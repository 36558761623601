import React, { useEffect, useState } from 'react';

const MainFooter = () => {
  const [footerContent, setFooterContent] = useState('');
  const [totalSize, setTotalSize] = useState(1000); // Initial total size
  const [insertPhrase, setInsertPhrase] = useState(' © 2024 Jim Dee. Thanks for visiting :-) ');
  const charsToUpdate = 5; // Change this to update N number of characters
  const maxIterations = 300; // Maximum number of iterations before regeneration
  const [iterationCount, setIterationCount] = useState(0);
  const letterClasses = ['letterBlue', 'letterYellow', 'letterRed', 'letterGreen'];

  useEffect(() => {
    if (footerContent.length < totalSize) {
      // If the current content length is less than the desired total size,
      // we append new characters to reach that size
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-=_+[]{}\|,.<>/?`~';
      const newChars = Array.from({ length: totalSize - footerContent.length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join(' ');
      setFooterContent(prevContent => prevContent + newChars);
    } else if (footerContent.length > totalSize) {
      // If the current content length exceeds the desired total size,
      // we truncate the content to match the total size
      setFooterContent(prevContent => prevContent.slice(0, totalSize));
    }
  }, [footerContent, totalSize]);

  useEffect(() => {
    // Update the content size initially or when totalSize changes
    setFooterContent(prevContent => prevContent.slice(0, totalSize));
  }, [totalSize]);

  useEffect(() => {
    // Periodically update a portion of the content
    const interval = setInterval(() => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-=_+[]{}\|,.<>/?`~';
      const randomIndex = Math.floor(Math.random() * footerContent.length);
      const newChars = Array.from({ length: charsToUpdate }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join(' ');
      const newContent = footerContent.slice(0, randomIndex) + newChars + footerContent.slice(randomIndex + charsToUpdate);
      setFooterContent(newContent);

      setIterationCount(prevCount => prevCount + 1);

      if (iterationCount % 100 === 0) {
        const phraseLength = insertPhrase.length;
        const trimmedContent = footerContent.slice(0, footerContent.length - phraseLength);
        const insertionIndex = Math.floor(Math.random() * trimmedContent.length);
        const updatedContent = trimmedContent.slice(0, insertionIndex) + insertPhrase + trimmedContent.slice(insertionIndex);
        setFooterContent(updatedContent);
      }

      if (iterationCount === maxIterations) {
        setFooterContent('');
        setIterationCount(0);
      }
    }, 100);
        //adjust interval above for speed...
        
    return () => clearInterval(interval);
  }, [footerContent, insertPhrase, iterationCount]); // Run whenever footerContent changes or insertPhrase changes

  return (
    <div className="mainpageFooter">
      {footerContent}
    </div>
  );
};

export default MainFooter;
