import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';

const BookWoods = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <p className="breadcrumbs"><a href="/books">Back to /books</a></p>
            <h1>WOOD'S CONFECTION</h1>

            <div className="rightPromoBox">
                <a href="https://www.amazon.com/gp/product/1703757750" target="_blank"><img src="../wood-paperback-cover.jpg" /></a><br></br>
                <Button href="https://www.amazon.com/gp/product/1703757750">Go to Amazon</Button> 
            </div>

            <img className="bookbanners" src='../article-wood.jpg' />

            <p><strong>Wood's Confection<em></em></strong> was my first screenplay, entered in the Nicholl Fellowship contest in 2005.</p>

            <p><strong>The Story:</strong> A retired marketing executive's latest entrepreneurial pursuit -- developing a candy bar in the shape of Jesus -- dovetails with an adventure involving his younger cousin's circle of criminal friends. The recipe for this tightly woven tale includes elements of dark comedy, mystery, and a dash of philosophy.</p>

            <p><strong>The Background Story:</strong> I had a good time writing this -- used to carry my laptop around and squeeze in a half-hour here, another half-hour there. It's not the best way to write, but it does keep your conscious and unconscious mind occupied with solving storyline problems that creep up. And this one had its share, most of which were resolved with real "aha"-type moments (which are, for me, among the more enjoyable parts of writing in any genre).</p>

            <p>Whether the next observation I have to share is good or bad may be a matter of opinion, but much of what's in here was inspired by real-life events. For example, the opening scene. That little boy in church was me, and the story of the teardrop is entirely true (though I changed the teacher's name to Mrs. Ross). Many other scenes actually happened as well (for example, the incident that inspires Wood to take up his entrepreneurial experiment).</p>

            <p>Other things, of course, were invented. As I suspect is the case for many screenwriters, there exists a related, virtual stack of background documents that, if ever printed out, would be a foot tall -- character sketches, research, long sections written out as fiction, plot outlines, whole short stories representing subplots that were ultimately cut from the action, even a bunch of photos of various actors I'd searched out to represent the characters in my mind's eye.</p>

            <p>And more. I daresay that, barring the highly unlikely event that I become one of those authors that grad students study in depth, many of the cleverly hidden secrets within <em>Wood's Confection</em> will go undiscovered. But, that's okay; I still get a private kick out of them.</p>

            <p>As for a rating, I'm afraid this one would certainly merit an R. There's foul language, a misogynist Russian, car thieves, various drunks, extremely lewd references, some strong racist language from a neo-Nazi, and a dash of porn for good measure. Not something you should let the kids read.</p>

            <p>Although it's a screenplay, I've formatted it as a paperback and have made it available online. Links to the paperback and Kindle eBook editions appear on the sides of this web page.</p> 	
            
            <Button href="https://www.amazon.com/gp/product/1703757750">Grab WOOD'S CONFECTION from Amazon!</Button>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default BookWoods;
