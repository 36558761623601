import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import ThreeJSCanvas from './ThreeJSCanvas'; 

const Contact = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <div className="MML">
              <ThreeJSCanvas /> 
            </div>
            
            <h1>CONTACT</h1>

            <p>➡️➡️➡️ <b>Drop me a line at Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>
                    
                    <p>or...</p>

            <p>➡️➡️➡️ <b>Drop me a line at Jim [at] ArrayWebDevelopment.com</b> ⬅️⬅️⬅️</p>

            <hr style={{marginTop:50}}></hr>
            <p style={{fontSize:13}}>The cat here is just a fun little AI-generated 3D model that you can interact with using the mouse, the up/down scroll or the space bar. (<a href="https://medium.com/web-design-web-developer-magazine/adding-an-ai-generated-3d-spinning-cat-to-a-react-app-using-meshy-ai-4de44aa5d008?sk=ffabee08b3c3b137f97a567514248fe0" target="_blank">How I did this</a>.)</p>

            <div className="clear"></div>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default Contact;
