import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const MusicLamb = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">

            <p className="breadcrumbs"><a href="/music">Back to /music</a></p>

            <h1>Joseph Lamb</h1>

            <table className="ragTable">

<tbody><tr><td colspan="3"><b>TITLES IN OUR COLLECTION</b> </td></tr><tr>
</tr><tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Lilliputian's Bazaar</p><img class="ragcover" src="../pics/lamb/lamb-lilliputians.jpg" /></td>
<td><p class="ragtext">1905, H.H. Sparks, Canada</p></td>
<td><p class="ragtext">Note that, on some of these early sheets, Lamb's name first is spelled "Josef." Our copy is a bit brittle, but it's a really tough-to-find sheet!
</p>
</td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">The Lost Letter</p><img class="ragcover" src="../pics/lamb/lamb-lostletter.jpg" /></td>
<td><p class="ragtext">1908, H.H. Sparks, Canada (same publisher as many of his early publications)</p></td>
<td><p class="ragtext">Few Lamb  collectors know about this sheet. We believe there are no more than a few copies in existence. 
</p>
</td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Sensation</p><img class="ragcover" src="../pics/lamb/joplin-sensation.jpg" /></td>
<td><p class="ragtext">1908, Stark</p></td>
<td><p class="ragtext">This sheet lists Scott Joplin as "arranger," which surely helped sales of the piece.</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Champagne Rag</p><img class="ragcover" src="../pics/lamb/lamb-champagne.jpg" /></td>
<td><p class="ragtext">1910, Stark</p></td>
<td><p class="ragtext">Here's a variation cover that we don't have -- with the title running vertically:</p><img class="ragcover" src="../pics/lamb/lamb-champagne2.jpg" />
<p class="ragtext">And here's a really strange "variation" (really, a mistake) crediting James Scott with this rag!</p>
<img class="ragcover" src="../pics/lamb/champagne-credtoscott.jpg" /><p class="ragtext">(Thanks to Ryan Wishner for sending this to me.)</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">American Beauty Rag</p><img class="ragcover" src="../pics/lamb/lamb-americanbeauty.jpg" /></td>
<td><p class="ragtext">1913, Stark</p></td>
<td><p class="ragtext">Interesting comparisons... Some earlier sheets of the same name:</p>
<p class="ragtext">American Beauty Two Step by Kathleen Whitlock, 1901:</p>
<img class="ragcover" src="../pics/lamb/other-beauty.jpg" />
<p class="ragtext">American Beauty Waltz, by Allen F. Wilson, 1903:</p>
<img class="ragcover" src="../pics/lamb/ambeauty-22.jpg" />
<p class="ragtext">[sorry, bad pic... composer unknown, from 1908]:</p>
<img class="ragcover" src="../pics/lamb/ambeauty3.jpg" />
</td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Nightingale Rag</p><img class="ragcover" src="../pics/lamb/lamb-nightingale.jpg" /></td>
<td><p class="ragtext">1915, Stark</p></td>
<td><p class="ragtext">&nbsp;</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Contentment Rag</p><img class="ragcover" src="../pics/lamb/lamb-contentment.jpg" /></td>
<td><p class="ragtext">1915, Stark</p></td>
<td><p class="ragtext">&nbsp;</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Topliner Rag</p><img class="ragcover" src="../pics/lamb/lamb-topliner.jpg" /></td>
<td><p class="ragtext">1916, Stark</p></td>
<td><p class="ragtext">&nbsp;</p></td>
</tr>

<tr><td colspan="3"><b>TITLES WE'RE SEEKING:</b> </td></tr><tr>
</tr><tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Celestine Waltzes</p><img class="ragcover" src="../pics/lamb/celesetine.jpg" /></td>
<td><p class="ragtext">1905, H.H. Sparks, Canada</p></td>
<td><p class="ragtext">Thanks to Ted Tjaden for the image. View his excellent Lamb site <a href="http://www.ragtimepiano.ca/rags/lamb.htm">here</a>.</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Florentine Waltzes</p><img class="ragcover" src="../pics/lamb/florentine.jpg" /></td>
<td><p class="ragtext">1906, H.H. Sparks, Canada</p></td>
<td><p class="ragtext">Thanks to Ted Tjaden for the info &amp; image. View his excellent Lamb site <a href="http://www.ragtimepiano.ca/rags/lamb.htm">
here</a>.</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Gee, Kid! But I Like You</p><img class="ragcover" src="../pics/lamb/lamb2-gee-kid.jpg" /></td>
<td><p class="ragtext">1908, Maurice Shapiro</p></td>
<td><p class="ragtext">&nbsp;</p></td>

</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Dear Blue Eyes: True Eyes</p><img class="ragcover" src="../pics/lamb/dearblue.jpg" /></td>
<td><p class="ragtext">1908, H.H. Sparks, Canada</p></td>
<td><p class="ragtext">Words by Llyn Wood</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">If Love is a Dream Let Me Never Awake</p><img class="ragcover" src="../pics/lamb/lovedream.jpg" /> </td>
<td><p class="ragtext">1908, H.H. Sparks, Canada</p></td>
<td><p class="ragtext">Words by Llyn Wood</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Love's Ebb Tide</p><img class="ragcover" src="../pics/lamb/lovesebbc.jpg" /></td>
<td><p class="ragtext">1908, H.H. Sparks, Canada</p></td>
<td><p class="ragtext">Words by Samuel A. White. Thanks to Ted Tjaden for the info &amp; image. View his excellent Lamb site <a href="http://www.ragtimepiano.ca/rags/lamb.htm">
here</a>.</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Three  Leaves of the Shamrock on the Watermelon Vine</p><img class="ragcover" src="../pics/lamb/threeleaves.jpg" /></td>
<td><p class="ragtext">1908, H.H. Sparks, Canada</p></td>
<td><p class="ragtext">Words by Harry Moore. Thanks to Ted Tjaden for the info &amp; image. View his excellent Lamb site <a href="http://www.ragtimepiano.ca/rags/lamb.htm">
here</a>.</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Love in Absence</p><img class="ragcover" src="../pics/lamb/lamb-loveinabsence.jpg" /></td>
<td><p class="ragtext">1909. Gordon Hurst. Music, NY</p></td>
<td><p class="ragtext">Never heard of this tune before but thanks to John Endries for sending the pic my way. The sheet clearly says Poem by Mary A. O'Reilly, Melody by Joseph F. Lamb. I'd be interested in any info on the piece anyone has out there...</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Ethiopia Rag</p><img class="ragcover" src="../pics/lamb/lamb-ethiopia.jpg" /></td>
<td><p class="ragtext">1909. Stark</p></td>
<td><p class="ragtext">&nbsp;</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Excelsior Rag</p><img class="ragcover" src="../pics/lamb/lamb-excelsior.jpg" /></td>
<td><p class="ragtext">1909, Stark</p></td>
<td><p class="ragtext">&nbsp;</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">I Want to Be a Birdman</p></td>
<td><p class="ragtext">1913, G. Satterlee</p></td>
<td><p class="ragtext">Anyone out there have a picture of this?</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">I'll Follow the Crowd to Coney</p></td>
<td><p class="ragtext">1913, G. Satterlee</p></td>
<td><p class="ragtext">Anyone out there have a picture of this? </p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Reindeer Rag</p><img class="ragcover" src="../pics/lamb/lamb-reindeer.jpg" /></td>
<td><p class="ragtext">1915, Stark</p></td>
<td><p class="ragtext">A rather common Lamb, I know... But somehow we still do not own a copy.</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Cleopatra Rag</p><img class="ragcover" src="../pics/lamb/lamb-cleo2.jpg" /></td>
<td><p class="ragtext">1915, Stark</p></td>
<td><p class="ragtext">See my notes under Reflection Rag on our Scott Joplin page for other pieces with this same cover.</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Happy-Go-Lucky</p></td>
<td><p class="ragtext">1916, McKinley Music</p></td>
<td><p class="ragtext">Anyone out there have a picture of this?</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Patricia Rag</p><img class="ragcover" src="../pics/lamb/lamb-patricia.jpg" /></td>
<td><p class="ragtext">1916, Stark</p></td>
<td><p class="ragtext">&nbsp;</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr><tr>
<td><p class="ragtitle">Bohemia Rag</p><img class="ragcover" src="../pics/lamb/lamb-bohemia.jpg" /></td>
<td><p class="ragtext">1919, Stark</p></td>
<td><p class="ragtext">&nbsp;</p></td>
</tr>

<tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

</tr></tbody></table>

            <div className="clear"></div>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default MusicLamb;
