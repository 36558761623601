import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Music = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>MUSIC</h1>
            <p>I enjoy composing ragtime piano music, string quartets, and various other works, and then setting those compositions to old public domain footage found online. Here are some of those, along with a few piano vids for good measure. (A more <a href="https://medium.com/hawthorne-crow/tagged/music-composition">full archive is here</a>.)</p>

            <div className="musicBox">
              <iframe src="https://www.youtube.com/embed/OxZi5Me42eY?si=ETehK6rM-EQk27Fs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a href="https://medium.com/hawthorne-crow/dorothy-and-the-bird-circus-an-original-ragtime-composition-71cb11cf525a">More info & sheet music</a>.
            </div>

            <div className="musicBox">
              <iframe src="https://www.youtube.com/embed/UMRXHdP6wU0?si=x9hdaShwudJpQyNI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a href="https://medium.com/hawthorne-crow/the-real-west-an-original-ragtime-piano-composition-9f6385c565af">More info & sheet music</a>.
            </div>

            <div className="musicBox">
              <iframe src="https://www.youtube.com/embed/Ua83sQZwpWE?si=r9XRD3Kso444tb7q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a href="https://medium.com/hawthorne-crow/the-leveret-a-ragtime-fantasie-1bb6405e1dca">More info & sheet music</a>.
            </div>

            <div className="musicBox">
              <iframe src="https://www.youtube.com/embed/-Mi9OsP_wXA?si=Qyxkk0eTucNSlefJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a href="https://medium.com/hawthorne-crow/12-23-original-ragtime-piano-composition-5c6a933018b5">More info & sheet music</a>.
            </div>

            <div className="musicBox">
              <iframe src="https://www.youtube.com/embed/gsagM9Nwqqw?si=zkDKalt5J9oMv3i1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a href="https://medium.com/hawthorne-crow/funeral-for-a-crow-an-original-string-quartet-111328fd2578">More info & sheet music</a>.
            </div>

            <div className="musicBox">
              <iframe src="https://www.youtube.com/embed/SQKAMhLM8O4?si=gZACY3wdKCVhUy5I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a href="https://medium.com/hawthorne-crow/nightmare-at-the-darlings-an-original-piece-for-piano-and-two-violins-41dfb213af55">More info</a>.
            </div>

            <div className="musicBox">
              <iframe src="https://www.youtube.com/embed/P94jhf3jFCA?si=NwC8vpVTibHDfqjp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a href="https://medium.com/hawthorne-crow/the-art-of-roughness-an-original-composition-ecc3f9e373f6">More info & sheet music</a>.
            </div>

            <div className="musicBox">
              <iframe src="https://www.youtube.com/embed/ov5eyWO0ogo?si=KvzRjGUVyScMqsRM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <a href="https://medium.com/hawthorne-crow/the-violin-experimental-looping-composition-4eb035e4472f">More info & sheet music</a>.
            </div>

            <div className="musicBox">
              <iframe className="fbiframe" src="https://www.facebook.com/plugins/video.php?&href=https%3A%2F%2Fwww.facebook.com%2Frawfood%2Fvideos%2F677375197348101%2F&show_text=false" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
              (No further info. Original piece, but unscored.)
            </div>

            <div className="musicBox">
              <iframe className="fbiframe" src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Frawfood%2Fvideos%2F836407787794307%2F&show_text=false" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
              <a href="https://medium.com/hawthorne-crow/el-dorado-chatelaine-original-ragtime-piano-composition-4bc728dce829">More info & sheet music</a>.
            </div>

            <div className="musicBox">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/ZOmhUvZ5Lks?si=9T9JOzHgL0ORTmSX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              Playing a piece by my friend <a href="https://davidthomasroberts.com">David Thomas Roberts</a>.

            </div>

            <div className="clear"></div>

            <h2>Other Musical Items</h2>
            <ul>
              <li>Check out <a href="/music/joplin">my family's historic Scott Joplin sheet music collection</a>.</li>
              <li>Our <a href="/music/scott">James Scott sheet music collection</a>.</li>
              <li>Our <a href="/music/lamb">Joseph Lamb sheet music collection</a>.</li>
              <li>For kicks ... <a href="/music/jim">my "Jim" sheet music collection</a>.</li>
            </ul>

            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Music;
