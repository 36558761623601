import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const Books = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>BOOKS & WRITING</h1>

            <hr className="pageHRs"></hr>

            <div className="allCards">

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="covers-frazy.jpg" />
                    <Card.Body>
                      <Card.Title>Frazy Creek</Card.Title>
                      <Card.Text>
                        A gritty novel about CAT GANGS in the LBC.
                      </Card.Text>
                      <Link to="frazy-creek">
                        <div className="d-grid"><Button variant="dark">Check It Out!</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="covers-chroo.jpg" />
                    <Card.Body>
                      <Card.Title>Chroo</Card.Title>
                      <Card.Text>
                        A comedic, literary novel about the world's richest dog.
                      </Card.Text>
                      <Link to="chroo">
                        <div className="d-grid"><Button variant="dark">Meet Chroo</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="covers-pirates.jpg" />
                    <Card.Body>
                      <Card.Title>Pirates of Pamlico Sound</Card.Title>
                      <Card.Text>
                        A supernatural fantasy-romance novella.
                      </Card.Text>
                      <Link to="pirates-of-pamlico-sound">
                        <div className="d-grid"><Button variant="dark">Explore If You Dare</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="covers-wood.jpg" />
                    <Card.Body>
                      <Card.Title>Wood's Confection</Card.Title>
                      <Card.Text>
                        A darkly comedic feature screenplay.
                      </Card.Text>
                      <Link to="woods-confection">
                        <div className="d-grid"><Button variant="dark">Follow the Sweets</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="covers-tales.jpg" />
                    <Card.Body>
                      <Card.Title>Tales of the Midwest</Card.Title>
                      <Card.Text>
                        A memoir of small-town delinquency.
                      </Card.Text>
                      <Link to="tales-of-the-midwest">
                        <div className="d-grid"><Button variant="dark">Journey to the Midwest</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="covers-geometric.jpg" />
                    <Card.Body>
                      <Card.Title>The Geometric Lover</Card.Title>
                      <Card.Text>
                        An early 90s poetry chapbook.
                      </Card.Text>
                      <Link to="https://medium.com/hawthorne-crow/the-geometric-lover-a-poetry-chapbook-from-1991-c030c1927101">
                        <div className="d-grid"><Button variant="dark">Head to Northeastern PA</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard twoLinks">
                  <Card>
                    <Card.Img variant="top" src="professional.jpg" />
                    <Card.Body>
                      <Card.Title>Professional Writing</Card.Title>
                      <Card.Text>
                        Many of my professional jobs centered around writing and communications.  
                      </Card.Text>
                      <Link to="/marketing">
                        <div className="d-grid"><Button variant="dark">Proposals & Marketing Communications</Button></div>
                      </Link>
                      <Link to="/addlexp">
                        <div className="d-grid"><Button variant="dark">Journalism & Editorial</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="gn-logo1.jpg" />
                    <Card.Body>
                      <Card.Title>Blog - NFTs and WebDev</Card.Title>
                      <Card.Text>
                        Hundreds of articles on NFTs and web development.
                      </Card.Text>
                      <Link to="https://medium.com/web-design-web-developer-magazine">
                        <div className="d-grid"><Button variant="dark">Geek Out</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="crowblog.jpg" />
                    <Card.Body>
                      <Card.Title>Blog - Tales, Rants, etc.</Card.Title>
                      <Card.Text>
                        Hundreds of articles, from low-brow humor to philosophy.
                      </Card.Text>
                      <Link to="https://medium.com/hawthorne-crow">
                        <div className="d-grid"><Button variant="dark">Weird, But Okay</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="wordblog.jpg" />
                    <Card.Body>
                      <Card.Title>Blog - Wonderful Words</Card.Title>
                      <Card.Text>
                        Tons of essays on word histories -- done as research for my novel Frazy Creek.
                      </Card.Text>
                      <Link to="https://medium.com/define-the-word">
                        <div className="d-grid"><Button variant="dark">Fascinating Etymology</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="apples.jpg" />
                    <Card.Body>
                      <Card.Title>Blog - Health & Diet</Card.Title>
                      <Card.Text>
                        From 2007-2011, my wife and I wrote ~900 articles on health and diet topics.  
                      </Card.Text>
                      <Link to="https://www.purejeevan.com/">
                        <div className="d-grid"><Button variant="dark">Get Healthy</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="witchlebowski.jpg" />
                    <Card.Body>
                      <Card.Title>Fan Fiction</Card.Title>
                      <Card.Text>
                        After all, why not combine The Big Lebowski and Harry Potter?
                      </Card.Text>
                      <Link to="https://medium.com/hawthorne-crow/introduction-and-background-for-the-witch-lebowski-bd89d567cc8b">
                        <div className="d-grid"><Button variant="dark">Full Tale & Intro</Button></div>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>

            </div>

            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default Books;
