import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const Carpentry = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>CARPENTRY</h1>

            <p>I'm only including this page here because I'm considering expanding it for possible use doing custom bookcases and libraries for people. (But, I haven't decided yet whether I want to do that and market it.) Over the years, I've rehabbed two homes top-to-bottom and have become quite good at most of the building trades -- demo, framing, drywall, electrical, plumbing, painting, flooring, cabinetry, general carpentry, total remodels. I've owned three homes in my life, all of them built in the early 1900s, so chances are good that I've tackled almost every typical type of build or remodel work -- from rebuilding kitchens and bathrooms from the studs out to even a full seismic retrofit. Regrettably, I've never kept a great photo record of these projects.</p>

            <div className="allCards glassPage">

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/10-10-lower-bath.jpg" />
                    <Card.Body>
                      <Card.Title>Bathroom</Card.Title>
                      <Card.Text>
                      My first bathroom remodel. Included a sink built into an antique vanity.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/10-10-sauna.jpg" />
                    <Card.Body>
                      <Card.Title>Sauna</Card.Title>
                      <Card.Text>
                      Part of that same bathroom -- included a giant sauna built into an old coal storage room.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/bookcases.jpg" />
                    <Card.Body>
                      <Card.Title>Built-in Book Cases</Card.Title>
                      <Card.Text>
                      Library-style book cases. Sorry, I don't have any better pics!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/bookcases1.jpg" />
                    <Card.Body>
                      <Card.Title>Built-in Book Cases</Card.Title>
                      <Card.Text>
                      New window and built-in book case with light.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/bookcase-ashram.jpg" />
                    <Card.Body>
                      <Card.Title>Built-in Book Cases</Card.Title>
                      <Card.Text>
                      Built these at an ashram in PA.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/outdoorkitchen.webp" />
                    <Card.Body>
                      <Card.Title>Outdoor Kitchen</Card.Title>
                      <Card.Text>
                      Outdoor kitchen at our Portland home.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/kitchen1.jpg" />
                    <Card.Body>
                      <Card.Title>Kitchen Remodel</Card.Title>
                      <Card.Text>
                      Full custom build -- all cabinets from scratch, island, granite tile work, flooring, etc.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/kitchen2.jpg" />
                    <Card.Body>
                      <Card.Title>Kitchen Remodel</Card.Title>
                      <Card.Text>
                      Another shot of the same kitchen.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/shed1.jpg" />
                    <Card.Body>
                      <Card.Title>Shed / Studio</Card.Title>
                      <Card.Text>
                      Post-and-beam construction of a small 160 SF studio. (Later added decking.)
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/attic1.jpg" />
                    <Card.Body>
                      <Card.Title>Attic Remodel</Card.Title>
                      <Card.Text>
                      Complete remodel of an attic into a bedroom.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/attic2.jpg" />
                    <Card.Body>
                      <Card.Title>Attic Remodel</Card.Title>
                      <Card.Text>
                      Second sleeping area.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

            </div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Carpentry;
