import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Resume = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>RESUMES</h1>
            
            <p>Here are a few resumes tailored for specific roles:</p>

            <ul>
                <li>Resume focused on <a href="/resume/editorial">editorial qualifications</a>.</li>
                <li>Resume focused on <a href="/resume/tech">tech qualifications</a>.</li>
                <li>Resume focused on <a href="/resume/social-media">social media, SEO, marketing qualifications</a>.</li>
                <li>Resume focused on <a href="/resume/general">general business management</a>.</li>
            </ul>

        </div>

        <MainFooter />

    </div>
  );
}

export default Resume;
