import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const Glass = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>STAINED GLASS WORK</h1>

            <span id="glass"></span>

            <div className="moreinfobox">
              <img className="businessLogos" src="glass01.jpg" />
              <i className="fa fa-info-circle" aria-hidden="true"></i> I usually document my projects. Here's one from 2020 when I was getting started -- <a href="https://medium.com/hawthorne-crow/making-a-stained-glass-version-of-the-red-hot-chili-peppers-logo-californication-album-cover-26c39a343a93" target="_blank">how I made a RHCP logo & album cover window.</a>


              <img className="businessLogos logospacer" src="contact.jpg" />
              <a href="/contact">Contact me now about doing glass work for you.</a>
            </div>

            <p className="restitle"><strong><span className="highlighter">Stained Glass and Furniture Artist,</span></strong> a new venture for me in 2023 -- to get into some complex, large-scale stained glass and furniture projects. I intend to make a series of large "statement" pieces marrying my love of stained glass, art, and furniture refurbishment, such as this piece from Summer 2023:</p>
              
              <img src="glass02.jpg" className="insetpics01" />

              <div className="clear"></div>

              <p>Because of their size, these pieces will by available only in the Portland / Seattle markets. For commission inquiries, you may reach me at <b>Jim [at] GenerativeNFTs.io</b>.</p>

              <p>I'm happy to consider commissions for normal stained glass windows as well. Here are some articles on other windows I've done:</p>


              <div className="allCards glassPage">

                  <div className="singleCard">
                    <Card>
                      <Card.Img variant="top" src="glass-rhcp.jpg" />
                      <Card.Body>
                        <Card.Title>RHCP</Card.Title>
                        <Card.Text>
                        Making a Stained Glass Version of the Red Hot Chili Peppers Logo & Californication Album Cover.
                        </Card.Text>
                        <Link to="https://medium.com/hawthorne-crow/making-a-stained-glass-version-of-the-red-hot-chili-peppers-logo-californication-album-cover-26c39a343a93">
                          <div className="d-grid"><Button variant="dark">Check It Out!</Button></div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="singleCard">
                    <Card>
                      <Card.Img variant="top" src="glass-lupin.jpg" />
                      <Card.Body>
                        <Card.Title>Loo Dog!</Card.Title>
                        <Card.Text>
                        Making a Stained Glass Portrait of My Daughter's Dog, Lupin!.
                        </Card.Text>
                        <Link to="https://medium.com/hawthorne-crow/making-a-stained-glass-portrait-of-my-daughters-dog-lupin-8ea79ae56b88">
                          <div className="d-grid"><Button variant="dark">Check It Out!</Button></div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="singleCard">
                    <Card>
                      <Card.Img variant="top" src="glass-catcher.jpg" />
                      <Card.Body>
                        <Card.Title>Sun Catcher</Card.Title>
                        <Card.Text>
                        Making an 889-Piece Stained Glass Window / Sun-Catcher.
                        </Card.Text>
                        <Link to="https://medium.com/hawthorne-crow/making-an-889-piece-stained-glass-window-sun-catcher-77c9a97c58c9">
                          <div className="d-grid"><Button variant="dark">Check It Out!</Button></div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="singleCard">
                    <Card>
                      <Card.Img variant="top" src="glass-levitation.jpg" />
                      <Card.Body>
                        <Card.Title>“Levitation”</Card.Title>
                        <Card.Text>
                        Making a Stained Glass Version of a Vintage “Levitation” Magician Poster.
                        </Card.Text>
                        <Link to="https://medium.com/hawthorne-crow/making-a-stained-glass-version-of-a-vintage-levitation-magician-poster-d97eb676b82a">
                          <div className="d-grid"><Button variant="dark">Check It Out!</Button></div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="singleCard">
                    <Card>
                      <Card.Img variant="top" src="glass-baron.jpg" />
                      <Card.Body>
                        <Card.Title>The Baron</Card.Title>
                        <Card.Text>
                        Making a Stained Glass Version of The Baron from Studio Ghibli's “The Cat Returns”.
                        </Card.Text>
                        <Link to="https://medium.com/hawthorne-crow/making-a-stained-glass-version-of-the-baron-from-studio-ghiblis-the-cat-returns-e3cfc8f86d7e">
                          <div className="d-grid"><Button variant="dark">Check It Out!</Button></div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </div>

              </div>

      <h2>Misc</h2>
      <ul>
        <li><a href="misc/spinner.html">Spinner visualizer utility</a>.</li>
      </ul>

          <h2>Want to Commission a Piece?</h2>

          <p>➡️➡️➡️ <b>Drop me a line at Jim [at] GenerativeNFTs.io</b> ⬅️⬅️⬅️</p>
                    
                    <p>or...</p>

            <p>➡️➡️➡️ <b>Drop me a line at Jim [at] ArrayWebDevelopment.com</b> ⬅️⬅️⬅️</p>


          <div className="clear"></div>

          <p className="topButton"><a href="#top">/top</a></p>
          <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Glass;
