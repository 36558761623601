import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';

const BookTales = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <p className="breadcrumbs"><a href="/books">Back to /books</a></p>
            <h1>TALES OF THE MIDWEST</h1>

            <img className="bookbanners" src='../tales-cover.jpg' />

            <p>My first book, <em>Tales of the Midwest</em> (a memoir), was published on Lulu.com in 2007, under my former pen name, Patrick Hillman. I've since taken the book out of print, but have made much of it available on my blog on Medium.com. So, for anyone interested, here's a link to a good starting place for this work.</p> 	
            
            <Button href="https://medium.com/hawthorne-crow/tales-of-the-midwest-intro-and-foreword-aa9fcaa6912a">Check out the TALES!</Button>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default BookTales;
