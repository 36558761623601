import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Webdev = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>ARRAY WEB DEVELOPMENT</h1>
            <p className="resyears">Nov. 2010 to 2021:</p>

            <div className="moreinfobox">
              <img className="businessLogos" src="array-logo.jpg" />

              <i className="fa fa-info-circle" aria-hidden="true"></i> See my Medium archives for hundreds of articles on the topic of <a href="https://medium.com/web-design-web-developer-magazine/archive/2019" target="_blank">web development</a>.


            </div>

            <p className="restitle"><strong><span className="highlighter">Principal Web Strategist,</span></strong> <a href="https://www.arraywebdevelopment.com/" target="_blank">Array Web Development, LLC</a>.  We were a web design and web development agency based in Portland, Oregon, serving clients worldwide. Highlights:</p>

            <ul>
                <li>Executed top-quality, state-of-the-art web sites, redesigns, API tie-ins, intranets, and database applications, meeting functional specs and exceeding client expectations.</li>

                <li>Boosted ROI for businesses by planning and pursuing profitable digital marketing campaigns, search engine optimization (SEO) initiatives, and content creation / distribution strategies (writing blog content and marketing communications, creating videos, and handling social media marketing) for increased traffic and sales.</li>

                <li>Published <em>Web Designer / Web Developer Magazine</em> by ideating, writing, and editing business- and tech-related articles to demonstrate expertise and increase traffic, branding, awareness. (Articles online <a href="https://medium.com/web-design-web-developer-magazine">here</a>, in the archive section going back to ~2017.)</li>

                <li><strong>Tech stack:</strong> -- CMSs, PHP, MySQL, CSS, HTML, JavaScript, React, JSX, Firebase, Github. From 2012-2021, I was a Joomla! and Gantry specialist, custom Joomla! development (migrations, customizations, overrides, PHP programming, custom DB applications, components / plugins / modules / extensions, various ecommerce packages), advanced Joomla! administration (Access Control Level setup, custom module work, Joomla! security work, performance tuning), expert-level CSS work including responsive design, significant bootstrap experience. From 2021 onward, any webdev work I do is more on the React / serverless side of things.</li> 
            </ul>

            <h4>Selected Project Examples</h4>
            <ul class="webdevpage">
              <li><img className="nfticons" src="awd01.jpg" /> <b>News Media Sites:</b> The Skanner News is an online newspaper web site with 25,000 articles, multiple users and user levels, and 100% custom through and through.
              </li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd02.jpg" /> <b>Financial Web Sites:</b> Columbia Willamette Investments is a slick international web site with a custom-programmed user-registration system, integrated with Google translate.</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd03.jpg" /> <b>Complex Catalog Systems:</b> Ligman Lighting features many hundreds of products categorized and sorted in numerous ways, powered by a custom relational database system.</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd04.jpg" /> <b>Upscale eCommerce Sites:</b> Mine Rat Minerals features museum-quality gem and minerals, fully ecommerce enabled using custom-coded Virtuemart templates.</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd05.jpg" /> <b>Construction Web Sites:</b> Tim Austin Construction's site features a photo-heavy theme and the wow.js animation library. Great SEO positioning for this site, too!</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd06.jpg" /> <b>Cause Marketing Platforms:</b> I did considerable back-end development and API integrations to facilitate GoDabo's cause-marketing platform.</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd07.jpg" /> <b>Nonprofit Web Sites:</b> Jackson Hill Foundation is a not-for-profit organization with a family of web sites focused on services and fundraising events. The organization raises money for sarcoma research and affected families.</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd08.jpg" /> <b>Educational Web Sites:</b> I implemented Oregon College of Oriental Medicine's in-house designs for a custom, responsive Gantry5 platform-based web site.
              </li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd09.jpg" /> <b>Association Web Sites:</b> Nice Gantry5-based site for this nonprofit organization, the Oregon Society of Artists. Online class and workshop signups, too.</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd10.jpg" /> <b>Services Web Sites:</b> Implementation of Human Systems Institute's custom designs via a Joomla-based CMS.</li>
              <li className="clearing"></li>

              <li><img className="nfticons" src="awd11.jpg" /> <b>Custom Intranet Systems:</b> Private, custom server-based systems to better manage your business. Even today, some of my systems still power lage-scale businesses!</li>
              <li className="clearing"></li>

            </ul>

            <ul>
              <li>Joomla! World Conference: Attendee 2012, presenter 2016 in Vancouver, BC. See video "<a target="_blank" href="https://www.youtube.com/watch?v=KcB3_mNoG58">JWC 2016 - Leveraging the JDatabase Class - Jim Dee</a>".</li>
            </ul>
            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Webdev;
