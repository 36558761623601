import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";


const copyToClipboard = () => {
  const resumeText = document.getElementById('resumeText').innerText;
  let totalText = '';
  const additionalText = document.getElementById('additionalText').value;
  if (additionalText) {
    let middleMessage = '\n____________________________________________________________________\nJIM DEE IS A PERFECT FIT FOR THE FOLLOWING ROLE: \n\n';
    totalText = resumeText + middleMessage + additionalText; 
  } else {
    totalText = resumeText + '\n' + additionalText; 
  }
  navigator.clipboard.writeText(totalText).then(() => {
    alert('Resume text copied to clipboard!');
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
}


const text = `
JIM DEE  
email:  jimdeepersonal@gmail.com
phone:  (503) 891-7153
website:  https://jpd3.com

____________________________________________________________________

PROFESSIONAL SUMMARY

Seasoned professional with extensive experience in business development, project management, digital marketing, and web development. Proven track record of managing high-level, high-budget, mission-critical projects and leading teams to achieve strategic objectives. Adept at combining technical expertise with business acumen to drive growth and enhance operational efficiency.

____________________________________________________________________

PROFESSIONAL EXPERIENCE

Co-Founder & Chief Generative NFT Coder at GenerativeNFTs.io

• Went viral for my generative art coding writings (considered perhaps the world's leading authority on rarity tables and other aspects of generative NFT coding).
• Co-founded GenerativeNFTs.io, a web3 consulting company specializing in large-scale NFT drop projects.
• Have served on ~30 worldwide generative NFT drop teams since early 2021 with $40+ million USD in total sales.
• Particularly experienced in complex, international, large-scale web3 project management including art pipeline management, art/metadata coding, NFT team strategy advisory services, and web3 dev coding.
• See: https://jpd3.com/nfts
• See https://generativenfts.io/


Principal Web Strategist at Array Web Development

• Led the design and execution of state-of-the-art websites, redesigns, and digital marketing campaigns.
• Published hundreds of articles to enhance brand presence and increase traffic.
• Specialized in SEO and social media marketing, achieving significant ROI for clients.
• Directed project teams, ensuring timely delivery and adherence to client specifications.
• See: https://jpd3.com/webdev
• See: https://www.arraywebdevelopment.com/


Director of Marketing & Business Development at Case | Sabatini CPAs

• Developed and executed comprehensive marketing and business development plans.
• Launched a successful tax eNewsletter, significantly expanding the firm’s client base.
• Created high-quality marketing materials, proposals, and business communications.
• Fostered relationships with key stakeholders to enhance business opportunities.


Senior Manager, Marketing Communications & Internet Services at Air & Waste Management Association

• Oversaw all marketing communications and web-based publishing.
• Supervised marketing and website personnel.
• Managed PR, advertising, and media relations for large-scale conferences and exhibitions.
• Enhanced online presence and engagement through strategic content management.


Marketing Communications Project Manager at Deloitte

• Wrote and managed over 200 proposals for audit, tax, and consulting engagements.
• Developed competitive intelligence programs and assisted with business development strategies.
• Coordinated cross-functional teams to ensure cohesive project execution.
• Enhanced client relationships through effective communication and proposal management.


Editor at Prentice Hall

• Edited and managed layout for legal journals, ensuring compliance with style guides.
• Wrote feature articles for the law firm management publication, Of Counsel.
• Coordinated with authors and contributors to ensure timely publication.


____________________________________________________________________

GENERAL BUSINESS SKILLS

• business development
• project management
• digital marketing & SEO
• web development & design
• content creation & strategy
• technical writing & documentation
• public relations & media relations
• team leadership & supervision
• strategic planning
• problem solving & critical thinking

____________________________________________________________________

INDUSTRY EXPERIENCE

• blockchain
• construction
• crypto / cryptocurrency
• editorial
• financial services
• legal
• magazines
• news
• nonprofit organizations
• professional services
• publishing
• saas
• startups
• technology

____________________________________________________________________

PUBLICATIONS & WRITING

• Authored two novels, a novella, a feature screenplay, and a memoir.
• Published thousands of blog articles on various platforms.
• Notable articles include topics on web development, generative art coding, and digital marketing strategies.
• See https://jpd3.com/books

____________________________________________________________________

EDUCATION

• B.A. English, Wilkes University

____________________________________________________________________

HOBBIES AND INTERESTS

• Stained glass work: https://jpd3.com/glass
• Music composition: https://jpd3.com/music
• Digital art: https://jpd3.com/art
• NFTs and web3: https://jpd3.com/nfts

____________________________________________________________________

SOCIAL MEDIA AND PORTFOLIO

• Amazon author page: https://amazon.com/author/jimdee
• Facebook author page: https://facebook.com/jpdbooks
• Facebook personal: https://facebook.com/rawfood
• Github: https://github.com/jpdnft
• Instagram: https://instagram.com/SwiggaJuice
• LinkedIn: https://linkedin.com/in/jimdee
• Medium: https://medium.com/@jimdee
• Twitter: https://twitter.com/SwiggaJuice
• Personal website: https://jpd3.com
• Books and writing:  https://jpd3.com/books

____________________________________________________________________

REFERENCES & RECOMMENDATIONS
• See https://www.linkedin.com/in/jimdee/
`;


const ResumeEditorial = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
          <p className="breadcrumbs"><a href="/resume">back to /resume list</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="#additionalText">add additional info</a></p>

            <h1>GENERAL RESUME</h1>
            
            <pre className="preText" id="resumeText" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>

            <textarea id="additionalText" placeholder="Paste additional information to append to this resume, then use copy link below."></textarea>

            <p><a href="###" onClick={copyToClipboard}>📋 Copy resume plus additional info.</a></p>

            <p className="breadcrumbs"><a href="/resume">back to /resume list</a></p>

        </div>

        <MainFooter />

    </div>
  );
}

export default ResumeEditorial;
