// src/CatModel.js
import React, { useRef, useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';

const CatModel = () => {
    const { scene } = useGLTF('CAT.glb'); // Ensure the correct path to the cat model
    const ref = useRef();
    const [isRotating, setIsRotating] = useState(true);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === ' ') { // Toggle rotation on spacebar press
                setIsRotating((prev) => !prev);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useFrame(() => {
        if (isRotating && ref.current) {
            // Speed of rotation:
            ref.current.rotation.y += 0.03; 
            ref.current.rotation.x += 0.005; 
        }
    });

    return <primitive ref={ref} object={scene} scale={2.5} />;
};

export default CatModel;
