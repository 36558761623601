import React from "react";
import Menu from './Menu';

const MainHeader = () => (
    <>
        <span id="top"></span>
        <div className="mainpageHeader">

            <div className="headerLeft">
            <img alt="cool pic" src='/logo1.webp' />
            </div>
            
            <div className="headerRight">
            <Menu />
            </div> 

            <div className="clear"></div>
        </div>
    </>
);

export default MainHeader;