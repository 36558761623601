import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Socials = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>SOCIALS</h1>
            <p className="restitle">See LinkedIn for references and testimonials given and received:</p>


              <ul className="fa-ul">

                <li><a href="https://amazon.com/author/jimdee" target="_blank">
                  <i className="fa-li fa fa-amazon" aria-hidden="true"></i> Amazon
                </a></li>

                <li><a href="https://www.facebook.com/rawfood/" target="_blank">
                  <i className="fa-li fa fa-facebook-square" aria-hidden="true"></i> Facebook
                </a></li>

                <li><a href="https://github.com/jpdnft" target="_blank">
                  <i className="fa-li fa fa-facebook-square" aria-hidden="true"></i> Github
                </a></li>

                <li><a href="https://www.instagram.com/SwiggaJuice/" target="_blank">
                  <i className="fa-li fa fa-instagram" aria-hidden="true"></i> Instagram 
                </a></li>

                <li><a href="https://www.linkedin.com/in/jimdee/" target="_blank">
                  <i className="fa-li fa fa-linkedin-square" aria-hidden="true"></i> LinkedIn
                </a></li>

                <li><a href="https://medium.com/@jimdee" target="_blank">
                  <i className="fa-li fa fa-medium" aria-hidden="true"></i> Medium
                </a></li>
                
                <li><a href="https://www.twitter.com/SwiggaJuice/" target="_blank">
                  <i className="fa-li fa fa-twitter-square" aria-hidden="true"></i> Twitter
                </a></li>	
              
                
              </ul>
              <div className="clear"></div>

              <p className="topButton"><a href="#top">/top</a></p>
              <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Socials;
