import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Marketing = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">

            <h1>MARKETING & BUSINESS DEVELOPMENT</h1>

            <hr className="pageHRs"></hr>

            <p><em className="highlight">Case | Sabatini CPAs</em></p>

            <p className="resyears">Feb. 2001 to Nov. 2010: </p>

            <div className="moreinfobox">
              <img className="businessLogos" src="cslogo.jpg" />
              <i className="fa fa-info-circle" aria-hidden="true"></i> Want my take on web sites for professional services firms? Read <a href="https://medium.com/web-design-web-developer-magazine/professional-services-firms-build-your-client-base-with-time-honored-web-site-best-practices-4c5bf18d126b" target="_blank">Professional Services Firms: Build Your Client Base with Time-Honored Web Site Best Practices</a>.</div>

            <p className="restitle"><strong><span className="highlighter">Director of Marketing &amp; Business Development</span></strong> for a Regional CPA and Wealth Management Firm.  <a href="http://www.casesabatini.com/" target="_blank">Case | Sabatini CPAs</a> is a regional public accounting firm based in the South Hills of Pittsburgh. Sister-company, <strong>CSD Financial</strong>, managed $50 million in assets. Highlights:</p>
            <ul>
              <li>Built an entire marketing and business development function. Wrote and implemented the firm's first-ever comprehensive general and industry-specific segment marketing and business development plans. Coached partners and managers on marketing, sales techniques, SWOT analyses, etc. Implemented a firmwide sales incentive and BD plan.</li>
              <li>Materially participated in $1 million in recurring new business during my first year with the firm. Leveraged proposal writing / pricing / strategy expertise to assist in landing a significantly higher percentage of work.</li>
              <li>Launched a successful Tax eNewsletter reaching 2,500 local business leaders. Initiative started with just 200 clients as both a marketing / PR and business development tool.</li>
              <li>Developed firm's entire government practice from the ground up since 2005. Firm doubled in size since I started in 2001. When firm split into two companies in 2005, we launched a public sector service line. Began with zero government clients; had many dozens in just a few years. Firm also acquired a post-secondary school practice (at my recommendation) in 2008, adding numerous clients into this overall practice and significantly improving profitability.</li>
              <li>Leveraged our position as trusted financial advisors to launch a successful retained search company. Launched CSD Career Services in 2007 to source financial controllers and CFOs for our client base. (I served as the primary Director of CSD Career Services, receiving 20-33% of placement fees.)</li>
              <li>Consistently produced top-quality marketing materials (brochures, ads, press releases, communications, web sites, etc.) within a fast-paced, deadline-oriented environment that required resourcefulness and the ability to translate financial and/or technical concepts into easily accessible language. Maintained information about our practices, professionals, and the marketplace for up-to-date use in our materials.</li>
              <li>Leveraged combined talents in writing, design, and programming to develop innovative products and services that set our firm apart from others. For example, I conceived, designed, wrote, and coded a Form 990 analysis tool for our web site's nonprofit industry area. Similarly, I developed an online calculator to help manufacturers and high-end retailers decide whether to use LIFO.</li>
              <li>Accompanied CPAs on sales calls and bid meetings. Lead the resulting proposal process and participated in pricing strategy. I estimate that, over approximately 10 years, I wrote about 500 audit, tax, and consulting proposals and letters.</li>
              <li>Served as the lead representative for college recruiting. Attended career fairs and gave presentations to accounting majors about public accounting as a career.</li>
              <li>Provided marketing consulting to firm clients. Mostly, this was for web development work and printed marketing collateral.</li>
              <li>Provided advanced computer consulting to clients. For example, innovated data crunching functions to help our CPAs determine the percentage of school tuition that came from Title IV funds. In other words: Out of an entire accounting firm (a place where they use Excel more than anywhere), they called me in to figure out how to implement the complex formulae required.</li>
              <li>Bottom line: A marketer needs to demonstrate measurable value and ROI in addition to producing marketing communications and collateral of unparalleled quality. In this way, marketers can become a true source of revenue to a firm instead of an overhead expense.</li>
            </ul>

            <p>An article on eNewsletters from this era, featuring my approach at the time:</p>
            <ul>
              <li>"<a href="/documents/Practical-Accountant-eNewsletters.pdf">Push Client E-Newsletter Penetration</a>," by Jeff Stimpson. Featured in <em>Practical Accountant</em>, August 2002.</li>
            </ul>
            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

            <a name="awma"></a>
            <hr className="pageHRs"></hr>


            <p><em className="highlight">Air & Waste Mgmt. Assn.</em></p>
            <p className="resyears">Apr. 1999 to Feb. 2001</p>

            <div className="moreinfobox">
              <img className="businessLogos" src="awmalogo.jpg" />
              <i className="fa fa-info-circle" aria-hidden="true"></i> Thoughts on nonprofits and nonprofit web sites: Read my  <a href="https://medium.com/web-design-web-developer-magazine/three-things-i-learned-working-in-nonprofit-management-and-why-i-left-1e4813dda3c1" target="_blank">Three Things I Learned Working in Nonprofit Management, and Why I Left</a>, and  <a href="https://medium.com/web-design-web-developer-magazine/heres-how-nonprofits-can-really-impress-the-funding-community-with-web-site-best-practices-921599c44cf6" target="_blank">Here's How Nonprofits Can Really Impress the Funding Community with Web Site Best Practices</a></div>

            <p className="restitle"><strong><span className="highlighter">Senior Manager, Marketing Communications &amp; Internet Services</span></strong> for a 10,000-Member Professional Trade Association. Headquartered in downtown Pittsburgh, the <a href="http://www.awma.org/" target="_blank">Air &amp; Waste Management Association</a> facilitates communication among environmental engineers worldwide. Highlights:</p>
            <ul>
              
              <li>Oversaw the international, 10,000-member association's marketing communications, as well as all web-based publishing and web-based services by applying web and traditional publishing best practices, maintaining a high standard of professionalism.</li>
              <li>Supervised marketing and website personnel that expanded web functionality via integration with association management software, resulting in a 10x increase in web site traffic.</li>
              <li>Handled marketing / media relations / advertising / PR for a 4,000+ attendee conference and 50,000 sq. ft. exhibition, realizing efficiency improvements each year.</li>
              <li>Created and maintained marketing partnerships by working with environmental companies to provide enhanced value to members of the association.</li>
              
            </ul>

            <p>Some published articles from this era:</p>
            <ul>
              <li>"<a href="/documents/internet-research-EM-oct99.pdf">Seek and Ye Shall Find</a>." Feature article in <em>EM</em> (Environmental Manager), October 1999.</li>
              <li>"<a href="/documents/internet-branding-EM-dec99.pdf">Brand New Opportunities in 2000</a>." Feature article in <em>EM</em>, October 1999.</li>
              <li>"<a href="/documents/internet-jobseekers-EM-feb00.pdf">The Seven Habits of Highly Effective Online Job Seekers</a>." Featured in <em>EM</em>, February 2000. (Also used this article as a handout for a speech delivered to environmental engineers in Salt Lake City, June 2000)</li>
              <li>"<a href="/documents/internet-hackers-EM-june00.pdf">The Chaotic H4CK3R Subculture</a>." Featured in <em>EM</em>, June 2000.</li>
            </ul>


            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>
            <a name="deloitte"></a>
            <hr className="pageHRs"></hr>


            <p><em className="highlight">Deloitte.</em></p>
            <p className="resyears">Feb. 1997 to Apr. 1999</p>

            <div className="moreinfobox">
              <img className="businessLogos" src="deloittelogo.jpg" />
              <i className="fa fa-info-circle" aria-hidden="true"></i> Thoughts on this experience: Read my <a href="https://medium.com/web-design-web-developer-magazine/what-i-learned-at-deloitte-and-why-i-left-cc9982aca414" target="_blank">What I Learned at Deloitte, and Why I Left</a>.</div>

            <p className="restitle"><strong><span className="highlighter">Marketing Communications Project Manager (Proposals &amp; Business Development)</span></strong> for a Global Professional Services Firm. <a href="http://www.deloitte.com">Deloitte</a> (then known as Deloitte &amp; Touche) is the largest of the Big Four professional services firms, employing 150,000 professionals worldwide. Our department supported 600 professionals in Pittsburgh alone, as well as CPAs and management consultants in several states. Highlights:</p>
            <ul>

              <li>Supported 600 regional CPAs and management consultants by <b>writing and project managing more than 200 proposals for audit, tax, and consulting engagements ranging up to $4 million in scope,</b> earning a top review of “Exceeds Expectations.” Also wrote and project managed a winning $2 million public sector “Office of the Chairman” proposal (reserved for clients of national significance). Wrote numerous Enterprise Resource Planning (ERP) implementation and consulting proposals for the mid-cap “Solutions” consulting group (ranging from roughly $75,000 to $4 million in scope).</li>
              
              <li>Assisted the regional Business Development Director by researching and profiling area executives and boards, resulting in more precise targeting abilities and increased sales.</li>
              
              <li>Developed a competitive intelligence program by researching outside firms and interviewing internal resources to inventory our intelligence assets, resulting in the firm's ability to leverage our best strengths and act quickly on client opportunities.</li>

              <li>Supervised writers, designers, and vendors.</li>

              <li>Wrote portions of client business plans.</li>

            </ul>


            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Marketing;
