import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Art = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>DIGITAL ARTWORK</h1>
            
            <p>I also enjoy doing custom-coded artwork from time to time, usually in the modality of glitch art. Typically for these, I will work with Midjourney (AI) to source initial materials, and then heavily Photoshop them for whatever purposes I have at the time, usually augmenting the work using coded elements as well. For example, here are some typical examples:</p>

            <div className="musicBox artbox">
              <img src="Atomic-500px.gif" />
            </div>

            <div className="musicBox artbox">
              <img src="CattleDog-500px.gif" />
            </div>

            <p>I keep a 1/1 collection of these types of pieces on OpenSea, <a href="https://opensea.io/collection/swiggajuice" target="_blank">here</a>.</p>
            
            <p>For my generative NFT sets, see my <a href="https://generativenfts.io/portfolio">Portfolio page</a> on GenerativeNFTs.com (scroll down to "Jim's Personal NFT Sets" on that page).</p>

            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

        </div>

        <MainFooter />

    </div>
  );
}

export default Art;
