import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";
import 'bootstrap/dist/css/bootstrap.min.css';


const MusicJoplin = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
           <p className="breadcrumbs"><a href="/music">Back to /music</a></p>

            <h1>The Dee Family Scott Joplin Sheet Music Collection</h1>

            <img className='leftpic' src="../pics/joplin/ScottJoplin.gif" />
            <p> <b>About this project:</b> In 1998, our family (all long-time rag enthusiasts) decided to assemble a premier collection of vintage ragtime sheet music, specializing in the works of Scott Joplin, the "King of Ragtime Writers." While other major rag composers are certainly well represented in our collection, our primary mission is to complete our Joplin collection. We're currently about half way finished. (It's fair to say that we could be nearly three quarters finished by now if we hadn't been financially lacking half the time!)</p>

            <p>We hope this page helps our pursuit. If any visitors have questions, suggestions, comments, etc. (or, especially if you know how we can acquire our next sheet), please let us know. (Donations of old sheet music collections are always welcome. Email us anytime!) In our effort to build a complete collection of Scott Joplin sheet music, we have inadvertently created (to our knowledge) the Internet's most comprehensive collection of digital images of Joplin's publications. (Sorry the pictures are so small, but we wanted to get them all onto a single page.) We have listed the titles below in order of their publication (or, at least our best guesses).</p>

            <p>Need to contact us about this, or have a question? Email Jim at Jim@ArrayWebDevelopment.com. I just wanted to add here that this has been a labor of love of ours for quite some time. God knows I've literally scoured thousand's of the country's flea markets, thrift stores, antique stores, paper shows, and internet site's seeking this stuff, as has my family. I know my mother always enjoyed such outings while she was alive ... so many sheet music hunting trips, auction bids, phone calls, and emails back and forth. Each acquisition reflects quite a bit of work (and often money, and often luck) from us all. So, on behalf of myself, my sister Jeanne, and my father, we hope you enjoy browsing this page.</p>

            <p>BTW, this collection was featured in Pittsburgh magazine in February 2007. <a href="../documents/Joplin-Article.pdf">Check it out</a>!</p>

            <table className='ragTable'>

                <tbody><tr>
                <td><p class="ragtitle">Please Say You Will</p><img class="ragcover" src="../pics/joplin/joplin2-please-say.jpg" /><p></p></td>
                <td><p class="ragtext">1895, M.L. Mantell, Syracuse, NY</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr>
                
                <tr>

                <td><p class="ragtitle">A Picture of Her Face</p><img class="ragcover" src="../pics/joplin/joplin2-picture-face.jpg" /><p></p></td>
                <td><p class="ragtext">1895, Leiter Brothers, Syracuse, NY</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">We haven't acquired this sheet yet (though it appeared in an internet auction in 2003).  However we did come across an interesting old advertising card from the publisher.</p></td>
                </tr>



                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Great Crush Collision March</p><img class="ragcover" src="../pics/joplin/joplin-crush.jpg" /><p></p></td>
                <td><p class="ragtext">1896, J.R. Fuller</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Thanks to <a href="http://www.perfessorbill.com" target="_blank">Perfessor Bill</a>  for the image.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Combination March</p><img class="ragcover" src="../pics/joplin/joplin-combination.jpg" /><p></p></td>
                <td><p class="ragtext">1896, Robert Smith</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Thanks to <a href="http://www.perfessorbill.com" target="_blank">Perfessor Bill</a>  for the image.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Harmony Club Waltz</p><img class="ragcover" src="../pics/joplin/joplin-harmony.jpg" /><p></p></td>
                <td><p class="ragtext">1896, Robert Smith</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Anyone out there have a color photo of this?  I'd love to post it here.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Original Rags</p><img class="ragcover" src="../pics/joplin/joplin-original.jpg" /><p></p></td>
                <td><p class="ragtext">1899, (Warner edition)</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">Still seeking 1st edition by publisher Carl Hoffman.  The cover for the 1st looks the same as the one pictured here.  Note:  This piece was "arranged" by Chas. N. Daniels. Apparently, Daniels lent his name to the cover to provide some credibility for the then relatively-unknown Joplin.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Maple Leaf Rag</p><img class="ragcover" src="../pics/joplin/joplin-mapleleafrag2.jpg" /><p></p></td>
                <td><p class="ragtext">1899, John Stark &amp; Son </p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">Original version, published in Sedalia, MO</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-maple-photo.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">Thanks to <a href="http://www.perfessorbill.com" target="_blank">Perfessor Bill</a>  for the image. I believe this is the 2nd edition cover. Here's the small inset:<img class="ragcover" src="../pics/joplin/joplin-maple-insetphoto.jpg" />I've never seen this edition for sale.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-mapleleaf-blackleaf.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">Also shown is an early leaf version, but with a black leaf.  Thanks to Keith Schubert for the photo!</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-maple3.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We have this version.</p></td>
                <td><p class="ragtext">Appearing here and below are the two more common editions. The one above, which bears the dedication to the Maple Leaf Club, is slightly older and more rare.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-mapleleafrag1.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We have this version.</p></td>
                <td><p class="ragtext">BTW, here's one more interesting Maple Leaf variation:  Look how "Stark" is misspelled!<img class="ragcover" src="../pics/joplin/joplin-starke.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Swipesy Cake Walk</p><img class="ragcover" src="../pics/joplin/joplin-swipesy2.jpg" /><p></p></td>
                <td><p class="ragtext">1900, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">w/ Arthur Marshall (seeking other versions that we don't have).<img class="ragcover" src="../pics/joplin/swipesy-inset.JPG" />The top three covers pictured here, I think?, are the original covers.  The question is:  Which one is the first edition???  Frankly, I don't know.  I suspect that they were published in the order shown:  Black copy first, white with red type next, and then the one with Joplin and Marshall's photos.  My only reasoning for this is that the one with the photos doesn't appear to have been designed (i.e., it looks like the photos were just thrown on at the last minute). <br></br>On the other hand, notice that the last one has no publisher (Stark) listed.  We all know that Stark was a master marketer, and surely wouldn't have been too likely to release a sheet without his name or logo. So, maybe my theory is completely backwards.  Perhaps the one with the photos was the first edition, and then Stark "fixed" the problem by adding his name -- and possibly also decided at this point to remove the composer photos.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-swipesy3.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin2-swipesy-early.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-swipesy.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We have this version.</p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-swipesy-alt2.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">This one is a recent addition to our online collection. Must be highly uncommon, as we never saw it until 2007!</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/feb09-swipesy.JPG" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">Feb. 2009: Wow, it's unusual that I get to update this site anymore, as we've seen just about every Joplin variation in existence. However, here's a strange one. The inset photo is the same odd one that appears in a 1902 variation  of A Breeze from Alabama (scroll down to see it a bit larger)!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Sunflower Slow Drag</p><img class="ragcover" src="../pics/joplin/joplin-sunflower.jpg" /><p></p></td>
                <td><p class="ragtext">1901, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">Joplin's first collaboration with Scott Hayden.  This is one of the more common Joplin publications.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Augustan Club Waltz</p><img class="ragcover" src="../pics/joplin/joplin-augustan.jpg" /><p></p></td>
                <td><p class="ragtext">1901, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">Ours is trimmed, but otherwise VG.  We'd like to acquire an untrimmed copy someday, but the sheet is really rare.  When we bought it, that was the only time I'd ever seen one for sale - and I haven't seen one since.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-augustan-alt.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">The alternate version shown below was unearthed by Paul Riseman (a sheet music and memorabilia dealer from Springfield, IL.  I believe I acquired our copy of Augustan (top photo) from Paul many years ago. He's a great source for rag collectors!  (Paul's site.) Here's a closer look at that alternate cover:<img class="ragcover" src="../pics/joplin/joplin-augustan-alt-close.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Peacherine Rag</p><img class="ragcover" src="../pics/joplin/joplin-peacherine2.jpg" /><p></p></td>
                <td><p class="ragtext">1901, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">Based on what I've seen in the marketplace, this piece, along with Nonpareil, will likely be one of the next few additions to our collection.  The top pic shows what I believe may be the earlier cover, although I'm really not sure which one was issued first. 
                I'm sorry to have passed on this sheet so many times years back, as it just doesn't surface as much.  At one time, I probably could have picked it up for $150 or so. Sounds cheap, of course. But, when you don't have an extra $150... </p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-peacherine.jpg" /><p></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Easy Winners</p><img class="ragcover" src="../pics/joplin/joplin2-easywinners-joplin.jpg" /><p></p></td>
                <td><p class="ragtext">1901, Scott Joplin Music Co.</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">This is the 1st Edition.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-easy1.jpg" /><p></p></td>
                <td><p class="ragtext">Shattinger</p></td>
                <td><p class="ragtext">We have this version.</p></td>
                <td><p class="ragtext">A May 2005 acquisition -- not the 1st ed., but still quite rare. The Shattinger version has the inset drawings in all red.  Stark put them in black, and reversed them from left to right.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-easywinners.jpg" /><p></p></td>
                <td><p class="ragtext">Stark, variation 1</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">The two Stark versions seem to differ only in the Stark logo. See bottom center of each photo.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/ew-difstarklogo.jpg" /><p></p></td>
                <td><p class="ragtext">Stark, variation 2</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">An Interesting comparison of two editions, Shattinger Piano & Music Company one on the left, Stark Music Co. on the right:<br></br>
                <img src="../pics/joplin/joplin-easy-shattinger.jpg" />
                <img src="../pics/joplin/joplin-easy-stark.jpg" /></p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-easy2.jpg" /><p></p></td>
                <td><p class="ragtext">unusual variation</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">An unusual variation that appeared on eBay.  Kind of interesting in that it had a photo of Joplin. I hadn't seen this variation before... The auction described it as large format, so it dates back to at least 1920, but little other information was offered.<img src="../pics/joplin/joplin-easy-photo.jpg" class="ragcover" /></p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/joplin/joplin-easy4.jpg" /><p></p></td>
                <td><p class="ragtext">unusual variation</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">And, here's yet another variation.  Same as above except there's no inset photo of Joplin -- just a strange symbol in its place.  Looks like something straight out of The Da Vinci Code.  Anyway, this sheet is dated 1912<img src="../pics/joplin/joplin-easysymbol.jpg" class="ragcover" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">I'm Thinking of My Pickanniny Days<img class="ragcover" src="../pics/joplin/joplin-iamthinking.jpg" /></p></td>
                <td><p class="ragtext">1901, Thiebes-Stierlin</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext"><img class="ragcover" src="../pics/joplin/joplin-thinking-inset.jpg" />Almost won this one once on eBay. Someone sniped it from me.  But, hey, I can't complain, as I've sniped my share of sheets as well!<br></br>If you're unfamiliar with early music, you may be somewhat shocked at the title of this piece (the term <i>pickaninny</i>  being widely known today as disparaging and offensive). However, please understand the context here: This site is meant as a historical reference. To state the obvious: Times were different 100 years ago. This cover is actually extremely mild as compared with hundreds of others published during that era.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Cleopha Two Step<img class="ragcover" src="../pics/joplin/joplin-cleopha.jpg" /></p></td>
                <td><p class="ragtext">1902, S. Simon</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">A 2003 addition to our collection.  Ours is much more vibrant than the one pictured here, so we'll likely update the photo at some point.  Cleopha is a march/two-step, but does have a bit of syncopation as well. Certainly one of the more beautiful covers, too!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Strenuous Life<img class="ragcover" src="../pics/joplin/joplin-strenuous1.jpg" /></p></td>
                <td><p class="ragtext">1902, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">I believe this top one is the original, first edition, though few have ever seen it.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-strenuos2.jpg" /></p></td>
                <td><p class="ragtext">1902, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this version. </p></td>
                <td><p class="ragtext">Joplin's composition honoring Teddy Roosevelt. By the way, here's another piece of sheet music entitled Strenuous Life. I'm not sure of the publication date, but it's probably from around the same time.  This one's by William J. Short, I believe published in Massachusetts.<img class="ragcover" src="../pics/joplin/strenuouslife-related.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">A Breeze from Alabama<img class="ragcover" src="../pics/joplin/joplin2-breeze.jpg" /></p></td>
                <td><p class="ragtext">1902, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Apparently, this cover was published with various inset photos. I would expect that the top example is the correct one--showing P.G, Lowery "World's Challenging Colored Cornetist and Band Master."<img class="ragcover" src="../pics/joplin/breeze-lowery2.jpg" /></p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-breeze2.jpg" /></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">I'm not sure who these other two fellows are.<img class="ragcover" src="../pics/joplin/joplin-breeze-inset2.jpg" />  </p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Elite Syncopations<img class="ragcover" src="../pics/joplin/joplin-elite.jpg" /></p></td>
                <td><p class="ragtext">1902, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">What a finale, eh?!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Entertainer<img class="ragcover" src="../pics/joplin/joplin-entertainer3.jpg" /></p></td>
                <td><p class="ragtext">1902, Stark</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">Shown here is the more usual version.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-entertainer-yellow.jpg" /></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">Here's another more usual version, printed in yellow.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-entertainer.jpg" /></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We have this version.</p></td>
                <td><p class="ragtext">Our version is an unusual dark blue.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Ragtime Dance Song<img class="ragcover" src="../pics/joplin/joplin2-ragtime-dance-song.jpg" /></p></td>
                <td><p class="ragtext">1902, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this one. </p></td>
                <td><p class="ragtext">Cover basically the same as the rag cover from 1906, but it says "Words &amp; Music by Scott Joplin." <img class="ragcover" src="../pics/joplin/joplin-ragtimedancesong.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">March Majestic<img class="ragcover" src="../pics/joplin/joplin-marchmajestic2.jpg" /></p></td>
                <td><p class="ragtext">1902, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">This is the original, large format, full-color version from 1902. It's only one I've ever seen or heard of. A few years ago, another collector (much more experienced than me) told me that, as far she knew, no one's found a 1902 large format version, let alone in full color. She said it was thought that the sheet was never really issued. Well, folks, as far as I'm concerned, we can with reasonable confidence now be assured that the piece indeed was published, likely with a particularly small press run! Our copy is authentic, vintage, and bears the expected publication date. I'd always hoped this existed! In the future, maybe I'll post better scan since apparently few have seen this in its true form.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-majestic.jpg" /></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">This is the more well known post-1920 (small format) single-color reprint.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">A Guest of Honor</p></td>
                <td><p class="ragtext">1903</p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">This isn't a single piece, but an entire opera. The whole thing was lost.  Somewhere out there, though, in someone's grandmother's basement, tucked away under a staircase or something... it's there.  </p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Something Doing<img class="ragcover" src="../pics/joplin/joplin-something2.jpg" /></p></td>
                <td><p class="ragtext">1903, Val A. Reis</p></td>
                <td><p class="ragtext">&nbsp;We have this one. </p></td>
                <td><p class="ragtext">&nbsp;Joplin's second collaboration with Scott Hayden</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Weeping Willow<img class="ragcover" src="../pics/joplin/joplin-weepingwillow-1.JPG" /></p></td>
                <td><p class="ragtext">1903, Val A. Reis</p></td>
                <td><p class="ragtext">&nbsp;We're seeking this one.</p></td>
                <td><p class="ragtext">Apparently came in a 2-color version as well as a 1-color version.  This sheet appeared on eBay in late 2003 (though we didn't win the auction). In May 2010, one appeared again on eBay. (Didn't get it, though.) A pic of that one:<img class="ragcover" src="../pics/joplin/weep4.jpg" /></p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-weepingwillow2.jpg" /></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/weeping3.jpg" /></p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">July 07:  This one appeared on eBay.  I was kind of holding out for an earlier edition, and also flat broke when I saw it.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Little Black Baby<img class="ragcover" src="../pics/joplin/joplin2-little-black-baby.jpg" /></p></td>
                <td><p class="ragtext">1903, Success Music Co.</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">A closer look at the inset photo:<img class="ragcover" src="../pics/joplin/joplin-lbb-inset.jpg" />Success Music used this cover design on other pieces as well.  The one shown below, for example (from our collection), is dated 1904.  However, elements of the design point to a use of this cover design prior to the publication of Little Black Baby (LBB) in 1903. For one, the outline of the composer's credit is more ornate on the cover below.  And, the inset photo is circular rather than square. It would probably be easier to go from the below cover to the LBB one than it would in the reverse direction.<img class="ragcover" src="../pics/joplin/joplin-littleblackbaby-samecover.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Palm Leaf Rag<img class="ragcover" src="../pics/joplin/joplin-palmleaf.jpg" /></p></td>
                <td><p class="ragtext">1903, Victor Kremer</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">Another relatively common Joplin sheet.  At least, it used to be relatively common.  It hasn't surfaced much in the past few years.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Maple Leaf Rag Song<img class="ragcover" src="../pics/joplin/joplin2-maple-leaf-song.jpg" /></p></td>
                <td><p class="ragtext">1903, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this one. </p></td>
                <td><p class="ragtext">Seen once on eBay, but condition was really poor.<br></br>BTW, check out a comparison with a sheet put out a decade later...<br></br><img src="../pics/joplin/minstrelguys.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Favorite<img class="ragcover" src="../pics/joplin/joplin-favorite.jpg" /></p></td>
                <td><p class="ragtext">1904, A.W. Perry &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Came really close to owning this one a year or two ago.  Another copy sold in November 2003 on eBay - too pricey for us to grab, though!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Sycamore<img class="ragcover" src="../pics/joplin/joplin-sycamore.jpg" /></p></td>
                <td><p class="ragtext">1904, Will Rossiter</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">"A Concert Rag"<br></br>Happened by this on eBay recently... another example of a rag named after the Sycamore tree. This one's by Theron C. Bennett, published by Remick in 1910:<img class="ragcover" src="../pics/joplin/bennett-sycamore.JPG" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Chrysanthemum<img class="ragcover" src="../pics/joplin/joplin-crysanthemum.jpg" /></p></td>
                <td><p class="ragtext">1904, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">The pic is not of our copy. Would really like to find a better copy of this.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-crysanthemumz.jpg" /></p></td> 
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We're seeking this version.</p></td>
                <td><p class="ragtext">I'm not 100% sure which is the more "common" of the two shown here... Crysanthemum doesn't show up for sale often. This pic was taken during a May 2014 visit to the Scott Joplin museum in St. Louis.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Cascades<img class="ragcover" src="../pics/joplin/joplin2-cascades-original.jpg" /></p></td> 
                <td><p class="ragtext">1904, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">This is the original cover, which has Joplin's picture:<img class="ragcover" src="../pics/joplin/joplin-cascades-inset.jpg" /></p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-cascades.jpg" /></p></td> 
                <td><p class="ragtext">&nbsp;</p></td>
                <td><p class="ragtext">We have this version.</p></td>
                <td><p class="ragtext">The more common version is green, as pictured.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-cascades2.jpg" /></p></td> 
                <td><p class="ragtext">1904, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this version. </p></td>
                <td><p class="ragtext">Also shown is a variation of the usual green cover, this time printed in red ink on white paper.  </p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Bethena<img class="ragcover" src="../pics/joplin/joplin-bethena2.JPG" /></p></td>
                <td><p class="ragtext">1905, T. Bahnsen Piano</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">A costly March 2007 acquisition (but understandably so, considering the extreme rarity). Inset close-up:<img class="ragcover" src="../pics/joplin/bethena-inset.jpg" /><img class="ragcover" src="../pics/joplin/bahnsen-logo.jpg" /><br></br>By the way, take a look at a real T. Bahnsen piano!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Binks' Waltz<img class="ragcover" src="../pics/joplin/joplin-binks.jpg" /></p></td>
                <td><p class="ragtext">1905, T. Bahnsen Piano</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Thanks to <a href="http://www.perfessorbill.com" target="_blank">Perfessor Bill</a>  for the image.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Sarah Dear<img class="ragcover" src="../pics/joplin/joplin2-sarah-dear.jpg" /></p></td>
                <td><p class="ragtext">1905, T. Bahnsen Piano</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Pretty rare. I've seen this just once, years back. Here's a blow-up of the inset photo:<img class="ragcover" src="../pics/joplin/sarah-inset.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Leola<img class="ragcover" src="../pics/joplin/joplin-Leola.jpg" /></p></td>
                <td><p class="ragtext">1905, American Music Syndicate</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext"></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Rosebud March<img class="ragcover" src="../pics/joplin/joplin-rosebud.jpg" /></p></td>
                <td><p class="ragtext">1905, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Eugenia<img class="ragcover" src="../pics/joplin/joplin-eugenia.jpg" /></p></td>
                <td><p class="ragtext">1906, Will Rossiter</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">Our copy is "okay." We'll probably upgrade at some point in the future.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Antoinette March and Two-Step<img class="ragcover" src="../pics/joplin/joplin-antoinette.jpg" /></p></td>
                <td><p class="ragtext">1906, Stark Music Company</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Saw this for sale recently, but had no cash!!!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">The Ragtime Dance<img class="ragcover" src="../pics/joplin/joplin-theragtimedance.jpg" /></p></td> 
                <td><p class="ragtext">1906, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">Would like to upgrade this one at some point, as our is trimmed. This is the instrumental rag version of the 1902 published song. Judging from eBay and from various mail auctions we participate in, there were at least two or three other pieces (by other composers) with the words ragtime dance in the title.  I have some pictures to post in the future of a few of these.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Good-bye Old Gal Good-bye<img class="ragcover" src="../pics/joplin/joplin-goodbye.jpg" /></p></td>
                <td><p class="ragtext">1906, The Foster-Calhoun Co., Evansville, Ind.</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">Acquired in August 2011. :-)  Words by H. Carroll Taylor, music by Mac Darden, arranged by Scott Joplin.<img class="ragcover" src="../pics/joplin/joplin-goodbye-inset.jpg" />Not sure if the two fellows pictured are (1) Taylor and Darden, the composers, or (2) Foster and Calhoun, the publishers.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Snoring Sampson <img class="ragcover" src="../pics/joplin/joplin2-snoring-sampson.jpg" /></p></td>
                <td><p class="ragtext">1907, University Music Pub. Co.</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">arr. for Harry La Mertha. Found that image at the Library of Congress site.  I suspect it's some kind of black and white copy. Never seen an original copy.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">When Your Hair is Like the Snow<img class="ragcover" src="../pics/joplin/joplin-hairlikethesnow.jpg" /></p></td> 
                <td><p class="ragtext">1907, Owen Spendthrift </p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Almost acquired a copy in early 2003... Here's a picture of Owen Spendthrift:<img class="ragcover" src="../pics/joplin/joplin-owenspendthrift.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Nonpareil<img class="ragcover" src="../pics/joplin/joplin-nonpariel2.jpg" /></p></td>
                <td><p class="ragtext">1907, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this one. </p></td>
                <td><p class="ragtext">Based on what I've seen in the marketplace, this piece, along with Peacherine, will be one of the next few additions to our collection. Someone recently offered to sell us a copy of this -- with a missing middle page.  We declined the offer.  It's rare, but it's not THAT rare.  Another better one will come along.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-nonpariel-1.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext"></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Searchlight Rag<img class="ragcover" src="../pics/joplin/joplin-searchlight.jpg" /></p></td>
                <td><p class="ragtext">1907, Joseph W. Stern</p></td>
                <td><p class="ragtext">We have the blue version.</p></td>
                <td><p class="ragtext">We FINALLY acquired this one! - August 2004.  I was surprised it took us so long to acquire this, as it's not THAT rare.  But, the wait was worth it, as ours is a near-mint copy. &nbsp;Our copy is the blue version, as shown at the top of the pictures here.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-searchlight-green.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Green variation.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-searchlight3.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Black variation.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/searchlight-red.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Red variation.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Gladiolus Rag<img class="ragcover" src="../pics/joplin/joplin-gladiolus.jpg" /></p></td>
                <td><p class="ragtext">1907, Jos. W. Stern</p></td>
                <td><p class="ragtext">We have this one </p></td>
                <td><p class="ragtext">We're seeking  other colored versions. <br></br>I've shown some known color variations below. Also, check this out... Here's a sheet from 5 years later, "Moving Picture Rag" by Al Dubin And Jos. A. Burke, published by Weymann &amp; Son out of Philadelphia. Too bad the original Stern cover didn't look that vibrant!<img class="ragcover" src="../pics/joplin/gladiolus-lookalike.JPG" /><img class="ragcover" src="../pics/joplin/gladi-lookalike-closer.JPG" />Another "Gladiolus" sheet, this one from 1906:<img class="ragcover" src="../pics/joplin/gladi-other.jpg" /></p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-glad-green.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Green variation.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-glad-purple.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Purple variation.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/joplin-glad-reddish.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Red variation (or possibly not a true variation...)</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/gladi-redhair.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Purple variation with inked hair. This rare pressing is from <a href="http://www.perfessorbill.com" target="_blank">Perfessor Bill's</a> collection.</p></td>
                </tr>
                <tr>
                <td><p class="ragtitle">(variation)<img class="ragcover" src="../pics/joplin/glad-bw.jpg" /></p></td>
                <td><p class="ragtext"></p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Finally, here's a black &amp; white version found in New Hampshire, from the collection of Alan, Phyllis &amp; Jonathan Carifio</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Lily Queen<img class="ragcover" src="../pics/joplin/joplin-LilyQueen.jpg" /></p></td>
                <td><p class="ragtext">1907, W.W. Stuart</p></td>
                <td><p class="ragtext">We're seeking this one. </p></td>
                <td><p class="ragtext">w/ Arthur Marshall<br></br>Question:  Is Lily Queen really as rare as everyone thinks it is???  Well, it certainly does command a premium every time it surfaces.  But, I've personally seen this title more than I've seen, say, Kismet Rag.  It's tough to gauge rarity, though. The observations I'm making on this page really only cover about 8 years of active collecting.  Many others have been collecting and researching Joplin ever since "The Sting" made him famous again.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Rose Leaf Rag<img class="ragcover" src="../pics/joplin/joplin-roseleaf.jpg" /></p></td>
                <td><p class="ragtext">1907, Joseph M. Daly</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext"></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Heliotrope Bouquet<img class="ragcover" src="../pics/joplin/joplin-heliotrope.jpg" /></p></td>
                <td><p class="ragtext">1907, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">w/ Louis Chauvin <br></br>Interesting note:  Here are two other 1907 sheets with the same cover.  The first is a song called "California" - words &amp; music by Bertie Frye. Published by Bertie Frye, Mcleansboro, IL.  The next is a Stark publication called "Calla Lily Rag" by Logan Thane. After that is another very rare one called Mildredda Waltz by LJ Fink (published in 1917 -- thanks to Suzanne Connelly Pautler for alerting me to this!).<img class="ragcover" src="../pics/joplin/joplin-heliotrope-lookalike.jpg" /><img class="ragcover" src="../pics/joplin/joplin-helio-lookalike2.jpg" /><img class="ragcover" src="../pics/joplin/mildredda.jpg" />
                </p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">School of Ragtime<img class="ragcover" src="../pics/joplin/joplin-school.jpg" /></p></td>
                <td><p class="ragtext">1908, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">&nbsp;</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Fig Leaf Rag<img class="ragcover" src="../pics/joplin/joplin-figleaf.jpg" /></p></td>
                <td><p class="ragtext">1908, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">One of my personal favorites. I love the C section.  Many performers, in my humble opinion, play this one too fast.  In 1909, there was another Fig Leaf Rag. Take a look:<img class="ragcover" src="../pics/joplin/Figleaf2-1909.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Sugar Cane<img class="ragcover" src="../pics/joplin/joplin-sugarcane.jpg" /></p></td>
                <td><p class="ragtext">1908, Seminary Music Co.</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">Ours is VG, perhaps slightly trimmed.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Sensation<img class="ragcover" src="../pics/joplin/joplin-sensation.jpg" /></p></td>
                <td><p class="ragtext">1908, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">--actually a Joseph Lamb tune -- with Joplin credited as "arranger," which surely helped sales of the piece. Be sure to take a look at our Joseph Lamb page as well!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Pine Apple Rag <img class="ragcover" src="../pics/joplin/joplin-pineapple.jpg" /></p></td>
                <td><p class="ragtext">1908, Seminary Music Co.</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">I used to see this around more in the marketplace. It's among the more common Joplin tunes (although still quite rare when compared to the vintage sheet music market in general).</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Wall Street Rag<img class="ragcover" src="../pics/joplin/joplin-wallstreet.jpg" /></p></td>
                <td><p class="ragtext">1909, Seminary Music Co.</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">We were lucky to have acquired an excellent copy of this rarity.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Solace<img class="ragcover" src="../pics/joplin/joplin-solace.jpg" /></p></td>
                <td><p class="ragtext">1909, Seminary Music Co.</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">This was the "holy grail" piece for us for quite some time.  We still can't believe that we actually own an original.  It's highly scarce, highly beloved by Joplin collectors, and commands a premium on the rare occasions that it surfaces in the marketplace.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Pleasant Moments<img class="ragcover" src="../pics/joplin/joplin-pleasant.jpg" /></p></td>
                <td><p class="ragtext">1909, Seminary Music Co.</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">We've had this one for years. However, until November 2003, we never saw another copy surface in the marketplace.  Chances are, it'll be a while before it surfaces again.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Country Club<img class="ragcover" src="../pics/joplin/joplin-countryclub.jpg" /></p></td>
                <td><p class="ragtext">1909, Seminary Music Co.</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">At one point, this one showed up rather regularly - say, once per year or so.  But, I haven't seen it for sale in 2 or 3 years.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Euphonic Sounds<img class="ragcover" src="../pics/joplin/joplin-euphonic.jpg" /></p></td>
                <td><p class="ragtext">1909, Seminary Music Co.</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">One of the few pieces with Joplin's photo on the cover. </p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Paragon Rag<img class="ragcover" src="../pics/joplin/Pargagon-ours.jpg" /></p></td>
                <td><p class="ragtext">1909, Seminary Music Co.</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">Saw for sale just once -- and luckily grabbed it!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Stoptime<img class="ragcover" src="../pics/joplin/joplin-stoptime.jpg" /></p></td>
                <td><p class="ragtext">1910, Joseph W. Stern</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">The last time I saw this one offered for sale, it came from an Australian dealer, I believe.  So, if you're in America, be prepared to scour the Earth for this rag! BTW, here's another instrumental "Stop Time" rag; it came out two years later, by Ernie Erdman.<img class="ragcover" src="../pics/joplin/stoptime-erdman.JPG" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Pine Apple Rag Song<img class="ragcover" src="../pics/joplin/joplin-pineapplesong.jpg" /></p></td>
                <td><p class="ragtext">1910, Seminary Music Co.</p></td>
                <td><p class="ragtext">We're seeking this one. </p></td>
                <td><p class="ragtext">Basically the same cover as the instrumental rag, but with a credit for the words:<img class="ragcover" src="../pics/joplin/joplin-pineapplewords.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Treemonisha <img class="ragcover" src="../pics/joplin/treemonisha-1.jpg" /></p></td>
                <td><p class="ragtext">1911, Scott Joplin Music Co.</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Not an individual sheet, but an entire opera score.  I would imagine this to be a large bound book. Indeed it is a large bound book. The cover is shown at left, thanks to a lucky visitor to this page who wrote to me seeing the value of this piece. I don't know the value, but would love to add this to our collection.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Felicity Rag<img class="ragcover" src="../pics/joplin/joplin-felicity.jpg" /></p></td>
                <td><p class="ragtext">1911, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">Joplin's third collaboration with Scott Hayden. We also found a "professional copy" (no cover) of this tune:<img class="ragcover" src="../pics/joplin/joplin-felic-prof%20copy.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Lovin' Babe<img class="ragcover" src="../pics/joplin/joplin-lovinbabe.jpg" /></p></td>
                <td><p class="ragtext">1911, The Robin Press</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">Words &amp; Music by Al. R. Turner, arranged by Joplin. Many thanks to <a href="http://www.perfessorbill.com" target="_blank">Perfessor Bill</a> for finding a copy of the cover page for me!</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Scott Joplin's New Rag<img class="ragcover" src="../pics/joplin/joplin-newrag.jpg" /></p></td>
                <td><p class="ragtext">1912, Joseph W. Stern</p></td>
                <td><p class="ragtext">We have this one.</p></td>
                <td><p class="ragtext">Our copy is trimmed, but otherwise VG.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Kismet Rag<img class="ragcover" src="../pics/joplin/joplin-kismet.jpg" /></p></td>
                <td><p class="ragtext">1913, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">Joplin's final collaboration with Scott Hayden.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">A Real Slow Drag<img class="ragcover" src="../pics/joplin/joplin-realslowdrag.jpg" /></p></td>
                <td><p class="ragtext">1913, Scott Joplin Music Co.</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">An individual sheet from Joplin's opera, Treemonisha. Anyone out there have a color photo of this?  I'd love to post it here.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Prelude to Act III<img class="ragcover" src="../pics/joplin/joplin-preludetoactiii.jpg" /></p></td>
                <td><p class="ragtext">1913</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">An individual sheet from Joplin's opera, Treemonisha. Anyone out there have a color photo of this?  I'd love to post it here.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Magnetic Rag<img class="ragcover" src="../pics/joplin/joplin-magnetic2.jpg" /></p></td>
                <td><p class="ragtext">1914, Scott Joplin Music Co.</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext"></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Silver Swan Rag<img class="ragcover" src="../pics/joplin/joplin-silverswan.jpg" /></p></td>
                <td><p class="ragtext">1914 (publ. early 1970s by the Maple Leaf Club.)</p></td>
                <td><p class="ragtext">We have this one. </p></td>
                <td><p class="ragtext">Probably did not appear as sheet music, but was issued on a piano roll.  Published in the early 1970s by the Maple Leaf Club. </p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Frolic of The Bears<img class="ragcover" src="../pics/joplin/joplin2-frolic-of-the-bears.jpg" /></p></td>
                <td><p class="ragtext">1915, Scott Joplin Music Co.</p></td>
                <td><p class="ragtext">We're seeking this one.</p></td>
                <td><p class="ragtext">An individual sheet from Joplin's opera, Treemonisha.</p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>


                </tr><tr>
                <td><p class="ragtitle">Reflection<img class="ragcover" src="../pics/joplin/joplin-reflection.jpg" /></p></td>
                <td><p class="ragtext">1917, John Stark &amp; Son</p></td>
                <td><p class="ragtext">We're seeking this one. </p></td>
                <td><p class="ragtext">Some earlier sheets bearing the same Starmer cover:<br></br>"Sunburst Rag" by James Scott, © 1909, Stark Music Co.:<img class="ragcover" src="../pics/joplin/scott-sunburst.jpg" />"Cleopatra Rag" by Joseph Lamb, © 1915 by Stark Music Co.:<img class="ragcover" src="../pics/joplin/lamb-cleopatra.jpg" />"Egyptian Maid," words: Robert Winter, music: Chas. Soutiea, © 1915 Syndicate Music Co., St. Louis:<img class="ragcover" src="../pics/joplin/joplin-reflection-lookalike.jpg" /></p></td>
                </tr>

                <tr class="ragline"><td class="ragline" colspan="4"></td></tr><tr>



                </tr></tbody>
            </table>

            <div className="clear"></div>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default MusicJoplin;
