import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";


const copyToClipboard = () => {
  const resumeText = document.getElementById('resumeText').innerText;
  let totalText = '';
  const additionalText = document.getElementById('additionalText').value;
  if (additionalText) {
    let middleMessage = '\n____________________________________________________________________\nJIM DEE IS A PERFECT FIT FOR THE FOLLOWING ROLE: \n\n';
    totalText = resumeText + middleMessage + additionalText; 
  } else {
    totalText = resumeText + '\n' + additionalText; 
  }
  navigator.clipboard.writeText(totalText).then(() => {
    alert('Resume text copied to clipboard!');
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
}


const text = `
JIM DEE  
email: Jim@GenerativeNFTs.io 
phone: (503) 891-7153
website: jpd3.com
linkedin: linkedin.com/in/jimdee
github: github.com/jpdnft

____________________________________________________________________

PROFESSIONAL SUMMARY

Prolific programmer, developer, and tech leader with extensive experience in web development, blockchain, and SEO. Proven track record in managing and delivering large-scale projects, generating significant revenue, and leading international teams. Expertise in smart contract development, generative NFTs, and full-stack web development. Skilled in digital marketing, content creation, and project management.

____________________________________________________________________

TECHNICAL SKILLS

• Programming & Development: 
  Solidity, React, JSX, JavaScript, PHP, MySQL, HTML, CSS, Hardhat, Node.js, Firebase, GitHub

• Blockchain & Web3: 
  Smart Contracts, ERC721/721A, Ethereum, Arweave, IPFS, Theta, OpenSea, MetaMask, Magic Eden

• SEO & Digital Marketing: 
  Search Engine Optimization, Content Strategy, Social Media Marketing, Google Analytics, Facebook Ads

• Project Management: 
  Agile Methodologies, Team Leadership, Strategic Planning, Art Pipeline Management, International Team Coordination

____________________________________________________________________

PROFESSIONAL EXPERIENCE

GenerativeNFTs.io -- Co-Founder & Chief Generative NFT Coder
Location: Remote, Duration: Ongoing

• Co-founded GenerativeNFTs, overseeing the production of ~350,000 generative NFTs with sales exceeding $40 million USD.
• Managed complex international web3 projects, including art pipeline management, coding, and team strategy advisory services.
• Deployed 30+ smart contracts on the Ethereum mainnet, contributing to the success of high-profile NFT projects.
• Led the development of a popular web3/NFT blog, creating 200+ articles on generative NFT art, team building, strategy, and crypto trends.
• Provided advisory services for NFT drop teams, ensuring multi-million-dollar project sellouts.


Array Web Development -- Principal Web Strategist
Location: Remote, Duration: Past

• Delivered high-quality web development projects using CMSs, PHP, MySQL, HTML, CSS, and JavaScript, consistently exceeding client expectations.
• Increased ROI for clients through effective digital marketing campaigns, SEO initiatives, and content creation strategies.
• Specialized in custom Joomla! development, including migrations, customizations, and advanced administration.
• Published hundreds of articles to enhance brand awareness and drive traffic, leveraging expertise in web development and digital marketing.


Case | Sabatini CPAs -- Director of Marketing & Business Development
Location: Pittsburgh, PA, Duration: Past

• Established and managed the firm's marketing and business development function, contributing to significant revenue growth.
• Developed comprehensive marketing and business development plans, leading to the acquisition of numerous high-value clients.
• Launched a successful eNewsletter and other digital marketing initiatives, increasing client engagement and retention.
• Provided advanced computer consulting and data analysis services, enhancing the firm's value proposition to clients.


Air & Waste Mgmt. Assn. -- Senior Manager, Marketing Communications & Internet Services
Location: Pittsburgh, PA, Duration: Past

• Oversaw marketing communications and web-based services for a 10,000-member association, significantly increasing web traffic and member engagement.
• Supervised marketing and website personnel, driving the expansion of web functionality and online presence.
• Managed marketing and PR for large-scale conferences and exhibitions, achieving efficiency improvements each year.

____________________________________________________________________

SELECTED TECH PROJECTS

• SuperFuzz Bad Guys: Led generative art coding and team strategy for this sold-out NFT project.
• Chef Dogs Gen1 & Gen2: Developed generative art, smart contracts, and minting apps for these ongoing projects.
• Praying Hands Club: Managed generative art, smart contracts, minting app, and marketing strategy for this sellout project.
• Loopies: Personal project where I handled everything from concept to execution, resulting in a successful free mint project.
• Order of the Tigons (NFT drop by 'Napoleon Dynamite' actor Jon Heder): Advised and handled all art coding for this sellout NFT project on the Theta blockchain.

____________________________________________________________________

ADDITIONAL TECH SKILLS

• AI
• APIs
• analytics
• arweave
• bitcoin
• blockchain
• blur
• cms
• chatGPT
• css
• crypto
• cryptocurrency
• dApps
• database application
• databases
• dex tools
• doge
• ens
• erc721
• erc721a
• eth
• ethereum
• ethers.js
• ethscriptions
• facet nfts
• firebase
• generative art coding
• github
• google analytics
• hardhat
• html
• inscriptions
• intranets
• ipfs
• javascript
• joomla
• jsx
• lamp
• magic eden
• meme coins
• metadata
• metaverse
• mysql
• netlify
• node.js
• non-fungible tokens
• opensea
• ordex
• php
• polygon
• problem solving
• programming
• react
• solidity
• uniswap
• web content
• web design
• web developer
• web development
• web designer
• web publishing
• web3
• web3 tech
• web3 trends
• wordpress

____________________________________________________________________

GENERAL BUSINESS SKILLS

• agency experience
• board of directors experience
• budgeting
• competitive intelligence
• consulting
• content strategy
• creativity
• critical thinking
• crm
• entrepreneurism
• event planning
• executive interfacing
• hr
• interpersonal skills
• leadership
• management
• marketing communications
• marketing strategy
• media relations
• microsoft excel
• microsoft office
• microsoft word
• negotiation
• nonprofit organizations
• organization skills
• partnership management
• pr / public relations
• presenting
• problem solving
• project management
• proposals
• public speaking
• research
• resourcefulness
• rfps
• strategic planning
• strategy
• supervision
• team building
• team leadership
• teamwork

____________________________________________________________________

INDUSTRY EXPERIENCE

• blockchain
• construction
• crypto / cryptocurrency
• editorial
• financial services
• legal
• magazines
• news
• nonprofit organizations
• professional services
• publishing
• saas
• startups
• technology

____________________________________________________________________

PUBLICATIONS & WRITING

• Notable topics include web development, generative art coding, and digital marketing strategies.
  See: https://medium.com/web-design-web-developer-magazine for hundreds of tech articles.
• Also authored two novels, a novella, a feature screenplay, and a memoir. 
  See https://jpd3.com/books
  

____________________________________________________________________

REPRESENTATIVE TECH ARTICLES

ChatGPT has selected the following articles as best likely representations of my writing on tech topics. Thank you, ChatGPT!

• 'Implementing a “Hello World” WebSocket App on Heroku' - https://medium.com/web-design-web-developer-magazine/implementing-a-hello-world-websocket-app-on-heroku-eab85abe08d0?sk=27f7b8ce02b3d62cdd5047f2c396b787
• 'Adding an AI-Generated 3D Spinning Cat to a React App Using Meshy.ai and ‘React Three Fiber’. Was It Easy?' - https://medium.com/web-design-web-developer-magazine/adding-an-ai-generated-3d-spinning-cat-to-a-react-app-using-meshy-ai-4de44aa5d008?sk=ffabee08b3c3b137f97a567514248fe0
• 'NFT Teams: Free Yourself from Never-Ending IPFS Pinning Fees: Uploading Images to Arweave via the CLI Method' - https://medium.com/web-design-web-developer-magazine/nft-teams-free-yourself-from-never-ending-ipfs-pinning-fees-uploading-images-to-arweave-via-the-a94ca51e99f4?sk=1ee94409f42858a96d9c0be638328efd 
• 'Festival Security vs. Web Site Security: Similarities Explored' - https://medium.com/hackernoon/festival-security-vs-web-site-security-similarities-explored-8cef55676809?sk=0614add4f039f60e8c93fa59784dd8c9
• 'Quick Hack to Refresh OpenSea Metadata on Many NFTs Quickly' - https://medium.com/web-design-web-developer-magazine/quick-hack-to-refresh-opensea-metadata-on-many-nfts-quickly-6b8a2a52b080?sk=cc402716c3ca0d71e8c6363b0bc4f541
• 'How To Integrate Web Sites with YouTube's API A Simple Example Using PHP' - https://medium.com/web-design-web-developer-magazine/how-to-integrate-web-sites-with-youtubes-api-a-simple-example-using-php-55f308afe6c5?sk=fc0b26d9c7dc552f97ecc5d77b755f4e
• 'Can You Use Your Own Completely New Made Up HTML Tags Instead of Normal H1 H2 etc' - https://medium.com/web-design-web-developer-magazine/can-you-use-your-own-completely-new-made-up-html-tags-instead-of-normal-h1-h2-etc-b9a2e08f3bdf?sk=567217f4c6a758f6f28a99cad6254c19
• 'Leveraging JS File Uploader Libraries in PHP Forms Example using FilePond' - https://medium.com/web-design-web-developer-magazine/leveraging-js-file-uploader-libraries-in-php-forms-example-using-filepond-754cdc9a8b48?sk=8acdac4ef204d2dc31ffc7bb501b851a
• 'With ETH Gas at 36, I Deployed & Config’d a 10k NFT Set for About $10 Using FacetNFT — Here’s How!' - https://medium.com/web-design-web-developer-magazine/with-eth-gas-at-36-i-deployed-configd-a-10k-nft-set-for-about-10-using-facetnft-here-s-how-1c9a4d8ef237?sk=9f1639f17707d1ade05673f394339cb4

____________________________________________________________________

EDUCATION

• B.A. English, Wilkes University

____________________________________________________________________

HOBBIES AND INTERESTS

• Stained glass
• Music composition
• Digital art

____________________________________________________________________

SOCIAL MEDIA AND PORTFOLIO

• Amazon author page: https://amazon.com/author/jimdee
• Facebook author page: https://facebook.com/jpdbooks
• Facebook personal: https://facebook.com/rawfood
• Github: https://github.com/jpdnft
• Instagram: https://instagram.com/SwiggaJuice
• LinkedIn: https://linkedin.com/in/jimdee
• Medium: https://medium.com/@jimdee
• Twitter: https://twitter.com/SwiggaJuice
• Personal website: https://jpd3.com
• Books and writing:  https://jpd3.com/books

____________________________________________________________________

REFERENCES & RECOMMENDATIONS
• See https://www.linkedin.com/in/jimdee/
`;


const ResumeTech = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
        <p className="breadcrumbs"><a href="/resume">back to /resume list</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="#additionalText">add additional info</a></p>

            <h1>TECH RESUME</h1>
            
            <pre className="preText" id="resumeText" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>

            <textarea id="additionalText" placeholder="Paste additional information to append to this resume, then use copy link below."></textarea>

            <p><a href="###" onClick={copyToClipboard}>📋 Copy resume plus additional info.</a></p>

            <p className="breadcrumbs"><a href="/resume">back to /resume list</a></p>

        </div>

        <MainFooter />

    </div>
  );
}

export default ResumeTech;
