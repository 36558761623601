import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const Addlexp = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>ADDITIONAL EXPERIENCE</h1>

            <hr className="pageHRs"></hr>

            <p><em className="highlight">Pure Jeevan</em></p>

            <p className="resyears">2007 to 2011</p>

            <div className="moreinfobox">
              <img className="businessLogos" src="purejeevanlogo.jpg" />

              <i className="fa fa-info-circle" aria-hidden="true"></i> Thoughts on this experience: Read my <a href="https://medium.com/web-design-web-developer-magazine/building-a-valuable-email-list-you-must-train-your-audience-that-they-are-in-fact-customers-126ff5082534" target="_blank">Building a Valuable Email List: You Must Train Your Audience That They Are, In Fact, Customers</a>.
            </div>

            <p className="restitle"><strong><span className="highlighter">Partner</span></strong> in a Holistic Wellness Information and Services Company. <a href="http://www.PureJeevan.com">Pure Jeevan</a> was launched by my wife in 2007 to serve the natural health and raw/living foods community. Together, we wrote articles, produced videos, and inspired people about raw foods, health, and nutrition. We also provided nutritional counseling, held events and talks, and offered health-related services such as live blood education. We had 10,000+ readers, with whom we were in contact regularly. Highlights included:</p>
            <ul>
              <li>The <a href="http://www.purejeevan.com/" target="_blank">Pure Jeevan web site</a> (featuring 900+ articles on raw foods, health, and related content)</li>
              <li>The All Raw Directory (a community-run site featuring thousands of links to raw resources)</li>
              <li>HolisticHealth.Me (a membership web site offering a 52-week holistic wellness program)</li>
            </ul>
            
            <p>Some published articles from this era:</p>
            <ul>
              <li><a target="_blank" href="http://www.purejeevan.com/interviews/myragoodman.html">Nurturing versus Conquering: Organic Farming as a Relationship with the Land -- An Interview with Myra Goodman, Cofounder of Earthbound Farm Organics,</a> 2009.</li>
              <li><a target="_blank" href="http://www.purejeevan.com/interviews/andygrant.html">The Colorado Organic Scene: An Interview with Andy Grant of Grant Family Organic Farms,</a> 2009.</li>
              <li><a target="_blank" href="http://www.purejeevan.com/interviews/index.html">Other Pure Jeevan-era interviews</a> (myself or my wife, Wendi, interviewing others / others interviewing us).</li>
            </ul>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>
            <a name="freelance"></a>
            <hr className="pageHRs"></hr>

            <p><em className="highlight">Freelance Writer, Editor, Publications</em></p>

            <p className="resyears">Mid-1990s - 2010</p>
            <p className="restitle"><strong><span className="highlighter">Freelance / Contract-Based</span></strong> Writer, editor, publisher, marketer, web designer, PR consultant, programmer, project manager. Billings, in addition to full-time positions listed above, averaged $10-30k annually.</p>

            <div className="moreinfobox"><i className="fa fa-info-circle" aria-hidden="true"></i> Thoughts on this experience: Read my <a href="https://writingcooperative.com/some-notes-on-writing-interview-articles-7e0776214f90" target="_blank">Notes on Writing Interview Articles</a>.</div>

            <p>Since so much of my corporate career focused on proprietary writing, I cannot publicly share samples of many such materials. However, I'm glad to have published numerous publicly available articles. Here are some from this era:</p>

            <ul>
              <li>"<a href="/documents/legal-stlouis-ofcounsel-feb97.pdf">St. Louis Law Firm Managers Assess Regional Dynamics</a>." Lead story in <em>Of Counsel</em><span className="genx">, February 1997</span>. (<em>Of Counsel</em> is one of the more prestigious national publications on law firm management.)</li>
              
              <li>"<a href="/documents/legal-Y2K-ofcounsel-april97.pdf">The Year 2000 Glitch: A Law Firm Business Problem and a Practice Development Opportunity</a>." Feature article in <em>Of Counsel</em><span className="genx">, April 1997</span>.</li>
              
              <li>"<a href="/documents/legal-headhunters-ofcounsel-may97.pdf">Law Firms Stress Dialogue As Key to Success Working with Headhunters</a>." Feature article in <em>Of Counsel</em><span className="genx">, May 1997</span>. (One managing partner interviewed was reportedly so impressed with this piece, he carried it around and handed it out to legal recruiters.)  </li>
              
              <li>"<a href="/documents/legal-pittsburgh-ofcounsel-sept97.pdf">Local Law Firms Happy Participants in Ongoing Regional Boom</a>." Feature article in <em>Of Counsel</em><span className="genx">, September 1997</span>.</li>
              
            </ul>


            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>

            <hr className="pageHRs"></hr>


            <div className="clear"></div>
            <p><em className="highlight">Prentice Hall</em></p>
            <p className="resyears">1993 to 1996</p>

            <div className="moreinfobox">
              <img className="businessLogos" src="prenticelogo.jpg" />
              <i className="fa fa-info-circle" aria-hidden="true"></i> Thoughts on this experience: Check out <a href="https://medium.com/web-design-web-developer-magazine/what-i-learned-at-prentice-hall-simon-schuster-and-why-i-left-80191500b7a" target="_blank">What I Learned at Prentice Hall / Simon &amp; Schuster, and Why I Left</a></div>

            <p className="restitle"><strong><span className="highlighter">Production Editor</span></strong> for a Global Information Services and Publishing Company. <strong>Prentice Hall Law &amp; Business</strong> was based in Washington, D.C<span className="genx">. when I began in 1993</span>. The publisher was part of Simon &amp; Schuster and, in turn, part of Paramount Communications, Inc. The unit sold <span className="genx">in 1995</span> to <strong>Aspen Publishers, Inc.</strong>, part of the Dutch publishing company, <strong>Wolters Kluwer</strong>. Highlights:</p>
            <ul>

              <li>Handled editing and layout for legal journals such as <em>The Computer Lawyer, Inside Litigation, SEC Insights, Commercial Contracts, The Investment Lawyer, ERISA Litigation Reporter,</em> and more, ensuring compliance with design and style guides (e.g., Chicago Manual of Style, AP Style Guide).</li>
              
              <li>Wrote various small columns and some feature articles for <em>Of Counsel,</em> a journal on law firm management.</li>
              
              <li>Prentice Hall Law &amp; Business was part of Simon &amp; Schuster / Paramount Communications, and later sold to Aspen Publishers (part of the global publishing company, Wolters Kluwer). Full-time<span className="genx"> '93-'95</span>, then continued as a contractor<span className="genx"> until '02</span> out of the New York office.</li>

            </ul>



            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>
            <hr className="pageHRs"></hr>



            <p><em className="highlight">The Cambridge Institute</em></p>

            <p className="resyears">1992 to 1993</p>


            <div className="moreinfobox"><i className="fa fa-info-circle" aria-hidden="true"></i> Thoughts on this experience: Read my <a href="https://medium.com/web-design-web-developer-magazine/career-retrospectives-1-top-three-lessons-learned-at-my-very-first-real-job-after-college-and-dd97c1f43772" target="_blank">Top Three Lessons Learned at My Very First “Real” Job After College, and Why I Left</a>.</div>

            <p className="restitle"><strong><span className="highlighter">Editor</span></strong> for a Continuing Legal Education (CLE) Provider. The Cambridge Institute was based in Vienna, VA, just outside of Washington, D.C.</p>
            <ul>
              <li>Designed, produced, and edited marketing brochures for CLE seminars across the U.S.</li>
            </ul>

            <div className="clear"></div>

            <p className="topButton"><a href="#top">/top</a></p>
            <div className="clear"></div>


        </div>

        <MainFooter />

    </div>
  );
}

export default Addlexp;
