import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const MusicScott = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">

            <p className="breadcrumbs"><a href="/music">Back to /music</a></p>

            <h1>JAMES SCOTT</h1>

            <table class="ragTable">

            <tbody><tr><td colspan="3"><b>TITLES IN OUR COLLECTION</b> </td></tr><tr>

            </tr><tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Frog Legs Rag</p><img class="ragcover" src="../pics/scott/scott-froglegs.jpg" /></td>
            <td><p class="ragtext">1906, Stark Music</p></td>
            <td><p class="ragtext">Two versions shown here... the usual green...</p></td>
            </tr>

            <tr>
            <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/scott/scott-froglegs-red.jpg" /></td>
            <td><p class="ragtext">&nbsp;</p></td>
            <td><p class="ragtext">...and one with a red background.</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Kansas City Rag</p><img class="ragcover" src="../pics/scott/scott-kc.jpg" /></td>
            <td><p class="ragtext">1907, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr>
            <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/scott/scott-kc-black.jpg" /></td>
            <td><p class="ragtext">&nbsp;</p></td>
            <td><p class="ragtext">I believe this also came in a dark grey version (which we do not own).</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Grace and Beauty</p><img class="ragcover" src="../pics/scott/scott-gracebeauty.jpg" /></td>
            <td><p class="ragtext">1909, Stark Music</p></td>
            <td><p class="ragtext">Here's another tune with the same cover: "Kansas City Kansas Frances" by Mae Fisher -- also from 1909. Based on the design, this could very well be Stark's first use of this cover.</p><img src="../pics/scott/kc-kansas-frances.jpg" class="ragcover" /></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Ragtime Betty</p><img class="ragcover" src="../pics/scott/scott-betty.jpg" /></td>
            <td><p class="ragtext">1909, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Valse Venice</p><img class="ragcover" src="../pics/scott/scott-venice.jpg" /></td>
            <td><p class="ragtext">1909, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Ophelia Rag</p><img class="ragcover" src="../pics/scott/scott-ophelia2.jpg" /></td>
            <td><p class="ragtext">1910, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Hilarity Rag</p><img class="ragcover" src="../pics/scott/scott-hilarity.jpg" /></td>
            <td><p class="ragtext">1910, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Princess Rag</p><img class="ragcover" src="../pics/scott/scott-princess.jpg" /></td>
            <td><p class="ragtext">1911, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Ragtime Oriole</p><img class="ragcover" src="../pics/scott/scott-oriole2.jpg" /></td>
            <td><p class="ragtext">1911 Stark Music</p></td>
            <td><p class="ragtext">Note: We have the usual greenish version...</p></td>
            </tr>

            <tr>
            <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/scott/scott-oriole1.jpg" /></td>
            <td><p class="ragtext">&nbsp;</p></td>
            <td><p class="ragtext">...but it apparently was also issued with a gold colored cover (which we don't own).</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Evergreen Rag</p><img class="ragcover" src="../pics/scott/scott-evergreen.jpg" /></td>
            <td><p class="ragtext">1915, Stark Music</p></td>
            <td><p class="ragtext">Another common cover design from the Stark catalog. Used later for Scott's Paramount Rag (scroll down a bit to see), and also for a Charles Hunter tune, Seraphine Waltz. (I'll have to check the year of this one.)</p><img class="ragcover" src="../pics/scott/seraphine.jpg" /></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Dixie Dimples</p><img class="ragcover" src="../pics/scott/scott-dixiedimples.jpg" /></td>
            <td><p class="ragtext">1918, Will Livernash Music Co.</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Springtime of Love</p><img class="ragcover" src="../pics/scott/scott-springtimeoflove.jpg" /></td>
            <td><p class="ragtext">1919</p></td>
            <td><p class="ragtext">There were apparently two publishers of this this song. More info to come on this, as I learn...</p></td>
            </tr>


            <tr><td colspan="3"><b>TITLES WE ARE SEEKING</b> </td></tr><tr>


            </tr><tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">A Summer Breeze</p><img class="ragcover" src="../pics/scott/scott-summerbreeze.jpg" /></td>
            <td><p class="ragtext">1903, Dumars Music Co., Carthage, MO</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">The Fascinator</p><img class="ragcover" src="../pics/scott/scott-fascinator.jpg" /></td>
            <td><p class="ragtext">1903, Dumars Music Co., Carthage, MO</p></td>
            <td><p class="ragtext">Dedicated to "my friend Miss Daisey N. Pierce." I wonder if this is her?<img class="ragcover" src="../pics/scott/fascinator-daisey.jpg" /></p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">On the Pike</p><img class="ragcover" src="../pics/scott/scott-pike.jpg" /></td>
            <td><p class="ragtext">1904, Dumars, Music Co., Carthage, MO</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Great Scott Rag</p><img class="ragcover" src="../pics/scott/scoot--greatscott2.jpg" /></td>
            <td><p class="ragtext">1909, Allen Music Co., Columbia, MO</p></td>
            <td><p class="ragtext"></p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">She's My Girl from Anaconda</p><img class="ragcover" src="../pics/scott/scott-anaconda.jpg" /></td>
            <td><p class="ragtext">1909, Dumars Gammon</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Sweetheart Time</p><img class="ragcover" src="../pics/scott/scott-sweetheart.jpg" /></td>
            <td><p class="ragtext">1909, Dumars Gammon</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Sunburst Rag</p><img class="ragcover" src="../pics/scott/scott-sunburst.jpg" /></td>
            <td><p class="ragtext">1909, Stark Music</p></td>
            <td><p class="ragtext">Two interesting notes: First, for the top cover, be sure to visit our Scott Joplin page and scroll down to Reflection Rag. You'll see other sheet music with the same cover.</p></td>
            </tr>

            <tr>
            <td><p class="ragtitle">(variation)</p><img class="ragcover" src="../pics/scott/scott-sunburst2.jpg" /></td>
            <td><p class="ragtext">&nbsp;</p></td>
            <td><p class="ragtext">This second one appears to be an original copy. Who knows if it's "the" original 1st edition cover, or if it's a later one??!</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Hearts Longing Waltzes</p><img class="ragcover" src="../pics/scott/scott-heartslonging.jpg" /></td>
            <td><p class="ragtext">1910, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Quality Rag</p><img class="ragcover" src="../pics/scott/scott-quality.jpg" /></td>
            <td><p class="ragtext">1911, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">The Suffragette Valse</p><img class="ragcover" src="../pics/scott/scott-suffragette.jpg" /></td>
            <td><p class="ragtext">1914, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Take Me Out to the Lake Side</p><img class="ragcover" src="../pics/scott/scott-lakeside.jpg" /></td>
            <td><p class="ragtext">1914, Ball Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Climax Rag</p><img class="ragcover" src="../pics/scott/scott-climax.jpg" /></td>
            <td><p class="ragtext">1914, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Honey Moon Rag</p><img class="ragcover" src="../pics/scott/scott-honeymoon.jpg" /></td>
            <td><p class="ragtext">1916, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Prosperity Rag</p><img class="ragcover" src="../pics/scott/scott-prosperity.jpg" /></td>
            <td><p class="ragtext">1916, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Efficiency Rag</p><img class="ragcover" src="../pics/scott/scott-efficiency.jpg" /></td>
            <td><p class="ragtext">1917, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Paramount Rag</p><img class="ragcover" src="../pics/scott/scott-paramount.jpg" /></td>
            <td><p class="ragtext">1917, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Rag Sentimental</p><img class="ragcover" src="../pics/scott/scott-ragsentimental.jpg" /></td>
            <td><p class="ragtext">1918, Stark Music</p></td>
            <td><p class="ragtext">Same cover as Troubador Rag, below.</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">New Era Rag</p><img class="ragcover" src="../pics/scott/scott-newera.jpg" /></td>
            <td><p class="ragtext">1919, Stark Music </p></td>
            <td><p class="ragtext">See "The Shimmie Shake" below.</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Peace and Plenty Rag</p><img class="ragcover" src="../pics/scott/scott-peacenplenty.jpg" /></td>
            <td><p class="ragtext">1919, Stark Music</p></td>
            <td><p class="ragtext">&nbsp;</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Troubadour Rag</p><img class="ragcover" src="../pics/scott/scott-troubador.jpg" /></td>
            <td><p class="ragtext">1919, Stark  Music</p></td>
            <td><p class="ragtext">Same cover as Rag Sentimental, above.</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Modesty Rag</p><img class="ragcover" src="../pics/scott/scott-modesty.jpg" /></td>
            <td><p class="ragtext">1920, Stark Music</p></td>
            <td><p class="ragtext">Note: Here's another sheet bearing the same cover design: Sapho Rag by J. Russell Robinson.</p><img class="ragcover" src="../pics/scott/sapho-rag.jpg" /></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Pegasus Rag</p><img class="ragcover" src="../pics/scott/scott-pegasus-color.jpg" /></td>
            <td><p class="ragtext">1920, Stark Music</p></td>
            <td><p class="ragtext">Super-interesting news! Here's a sheet that sold on eBay recently from 1908 - clearly an earlier use of this cover. Same title, but by Robert B. Sterling.</p><img class="ragcover" src="../pics/scott/sterling-pegasus1908.jpg" /></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">The Shimmie Shake</p><img class="ragcover" src="../pics/scott/scott2-shimmie.jpg" /></td>
            <td><p class="ragtext">1920, Stark Music</p></td>
            <td><p class="ragtext">Same cover as New Era Rag from 1919.</p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Don't Jazz Me - Rag, I'm Music</p><img class="ragcover" src="../pics/scott/scott2-dont-jazz-me.jpg" /></td>
            <td><p class="ragtext">1921, Stark Music</p></td>
            <td><p class="ragtext">Here's another Stark publication using the same cover. It's "Twilight" by E.J. Stark. I don't have a date on this one, so I'm not sure if it's an earlier use than the Don't Jazz Me sheet.</p><img class="ragcover" src="../pics/scott/twilight.jpg" /></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Victory Rag</p><img class="ragcover" src="../pics/scott/scott-victory.jpg" /></td>
            <td><p class="ragtext">1921, Stark Music</p></td>
            <td><p class="ragtext">Hmmm, I'm beginning to wonder if all of these late Scott rags used recycled covers from the Stark inventory. Here's another example. The picture below is "The Winged Victory" March &amp; Two-Step by E.J. Stark, copyright 1902.</p><img class="ragcover" src="../pics/scott/wingedvictory-ejstark-1902-scottlookalike.jpg" /></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">Broadway Rag</p><img class="ragcover" src="../pics/scott/scott2-broadway.jpg" /></td>
            <td><p class="ragtext">1922, Stark Music</p></td>
            <td><p class="ragtext">Here's a pic of what I believe may be the first Stark use of this cover. It's Dreamland Waltz by Manuel Cortez, publ. 1912.<img class="ragcover" src="../pics/scott/dreamland.jpg" /></p></td>
            </tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr><tr>
            <td><p class="ragtitle">March Sumner</p><img class="ragcover" src="../pics/scott/scott-sumner.jpg" /></td>
            <td><p class="ragtext">1929, Ophelia Watts Jackson, Kansas City, Mo.</p></td>
            <td><p class="ragtext">note: by Ophelia Watts Jackson, arranged by James Scott.
            </p></td></tr>

            <tr class="ragline"><td class="ragline" colspan="3"></td></tr><tr>

            </tr></tbody></table>

            <div className="clear"></div>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default MusicScott;
