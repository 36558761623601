import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

const MusicJim = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <p className="breadcrumbs"><a href="/music">Back to /music</a></p>

            <h1>"Jim" Sheet Music</h1>

            <p>Having collected sheet music for several years, I have happened across numerous sheets bearing my name. A few years ago, I began picking them up (as long as they were cheap), and have amassed a small collection that should be interesting to anyone who is also named "Jim." Hope you like it...</p>
            <p>Notes: (1) Almost all of the titles below are large-format (i.e., pre-1920) sheets. (2) Be aware that some of the sheets below depict offensive racial stereotypes prevalent during the period in which these sheets were published.</p>

                <table className="jimTable">
                <tbody><tr>
                <td colspan="3"><b>Titles I own:</b></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-baby.jpg"></img></td>
                <td><img src="../pics/jim/jim-bronco.jpg"></img></td>
                <td><img src="../pics/jim/jim-gunner.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-jim.jpg"></img></td>
                <td><img src="../pics/jim/jim-ragtime.jpg"></img></td>
                <td><img src="../pics/jim/jim-ragtimejimmie.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-christmas.jpg"></img></td>
                <td><img src="../pics/jim/jim-railroad.jpg"></img></td>
                <td><img src="../pics/jim/jim-jimmieboy.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-outofstep.jpg"></img></td>
                <td><img src="../pics/jim/jim-rubberneck.jpg"></img></td>
                <td><img src="../pics/jim/jim-sunny1.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-sunny3.jpg"></img></td>
                <td><img src="../pics/jim/jim-sunny2.jpg"></img></td>
                <td><img src="../pics/jim/jim-sunny4.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-whistling2.jpg"></img></td>
                <td><img src="../pics/jim/jim-corporal.jpg"></img></td>
                <td><img src="../pics/jim/jim-jimcrow.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-luckyjim-2.jpg"></img></td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                </tr>
                <tr>
                <td colspan="3"><b>Titles or versions I do not own, but have seen around:</b></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-jitney.jpg"></img></td>
                <td><img src="../pics/jim/jim-prancin.jpg"></img></td>
                <td><img src="../pics/jim/jim-jim-a-da-jeff.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-struttin.jpg"></img></td>
                <td><img src="../pics/jim/jim-whistling1.jpg"></img></td>
                <td><img src="../pics/jim/whistlingjim-red.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-win.jpg"></img></td>
                <td><img src="../pics/jim/jim-jimjim2.jpg"></img></td>
                <td><img src="../pics/jim/jim-jimalongjosey.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-plain.jpg"></img></td>
                <td><img src="../pics/jim/jim-coocoo.jpg"></img></td>
                <td><img src="../pics/jim/jim-dandy.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-crow2.jpg"></img></td>
                <td><img src="../pics/jim/jim-dandy2.jpg"></img></td>
                <td><img src="../pics/jim/jim-crow3.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-brown.jpg"></img></td>
                <td><img src="../pics/jim/jim-lucky.jpg"></img></td>
                <td><img src="../pics/jim/jim-fisk.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-jam.jpg"></img></td>
                <td><img src="../pics/jim/jim-judson.jpg"></img></td>
                <td><img src="../pics/jim/jim-jamreview.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-mississippi.jpg"></img></td>
                <td><img src="../pics/jim/jim-sunny-green.jpg"></img></td>
                <td><img src="../pics/jim/jim-sunny-kidpic.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-overalljim.jpg"></img></td>
                <td><img src="../pics/jim/jim-jimtownblues.jpg"></img></td>
                <td><img src="../pics/jim/jim-jimcrow2.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-throttle.jpg"></img></td>
                <td><img src="../pics/jim/jim-theme.jpg"></img></td>
                <td><img src="../pics/jim/bowlingjim.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-khaki-jim.jpg"></img></td>
                <td><img src="../pics/jim/piccadillyjim.jpg"></img></td>
                <td><img src="../pics/jim/jim-jimcarterlad.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-drjim.jpg"></img></td>
                <td><img src="../pics/jim/jim-ragtimejimmie2.jpg"></img></td>
                <td><img src="../pics/jim/jim-gijim.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-charcoal.jpg"></img></td>
                <td><img src="../pics/jim/jim-oldone.jpg"></img></td>
                <td><img src="../pics/jim/jim-black-jim.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-outofstep2.jpg"></img></td>
                <td><img src="../pics/jim/jumbojim.jpg"></img></td>
                <td><img src="../pics/jim/jim-dontbesorough.jpg"></img></td>
                </tr>
                <tr>
                <td><img src="../pics/jim/jim-inwalkedjimmy.jpg"></img></td>
                <td><img src="../pics/jim/jim-lickyjim.jpg"></img></td>
                <td><img src="../pics/jim/jim-ragtime2.jpg"></img></td>
                </tr>
                </tbody></table>

            <div className="clear"></div>
            
        </div>

        <MainFooter />
        <div className="clear"></div>

        <p className="topButton"><a href="#top">/top</a></p>
        <div className="clear"></div>

    </div>
  );
}

export default MusicJim;
